// @mui
import { Typography, Stack, Container, Link } from '@mui/material';
// components
import { SocialsButton } from '../../../components';

// ----------------------------------------------------------------------

export default function ContactHero() {
	return (
		<Container
			sx={{
				py: { xs: 8, md: 10 },
				textAlign: { xs: 'center', md: 'left' }
			}}
		>
			<Typography variant="h2">Ta kontakt med oss!</Typography>
			<Typography
				variant="subtitle1"
				sx={{
					mt: 3,
					mb: { xs: 3, md: 6 },
					fontFamily: 'sans-serif'
				}}
			>{'Vi ønsker å vite hvordan vi kan hjelpe deg!'}</Typography>

			<Stack spacing={{ xs: 3, md: 5 }} direction={{ xs: 'column', md: 'row' }}>
				<Stack spacing={1}>
					<Typography variant="subtitle2" sx={{ color: 'primary.main', fontFamily: 'sans-serif' }}>
                        Email
					</Typography>
					<Link variant="body1" color="inherit" href="mailto:hello@example.com">
                        kontakt@norskins.no
					</Link>
				</Stack>

				<Stack spacing={1} alignItems={{ xs: 'center', md: 'flex-start' }}>
					<Typography variant="subtitle2" sx={{ color: 'primary.main', fontFamily: 'sans-serif' }}>
                        Følg oss
					</Typography>
					<SocialsButton sx={{ color: 'text.primary' }} links={{ facebook: 'https://www.facebook.com/Norskins', twitter: 'https://twitter.com/norskinscsgo' }} />
				</Stack>
			</Stack>
		</Container>
	);
}