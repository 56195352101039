import { Box, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { useContext } from 'react';
import Image from '../../../components/Image';
import { ShoppingCartContext } from '../../../contexts/ShoppingCartContext';
import conditionShortner from '../../../utils/conditionShortner';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const ChosenSkinCard = ({ item, index, balance = false }) => {
	const { imagelink, skinName, condition, weapon, bought_price } = item;
	const imageLink=imagelink;
	const { handleClick } = useContext(ShoppingCartContext);
	
	return (
		<Card
			sx={{
				backgroundColor: 'rgba(38, 44, 51, 0.75);',
				mt: index !== 0 ? 1 : 0,
				borderRadius: 0,
				pb: 2,
				cursor: 'pointer',
			}}
			onClick={() => handleClick(item)}
		>
			<Stack>
				<Box sx={{ position: 'absolute', top: 0, right: 10 }}>
					<DeleteForeverIcon />
				</Box>
				<Box sx={{ width: '100%', display: 'flex' }}>
					<Box
						sx={{
							width: '35%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Box sx={{ width: '75%' }}>
							<Image src={imageLink.includes('http://') ? imageLink : `https://community.cloudflare.steamstatic.com/economy/image/${imageLink}`} width={0.75} ratio="4/3" />
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							pt: 2,
							width: '65%',
						}}
					>
						<Typography
							variant="body2"
							sx={{
								lineHeight: 1.0625,
								fontWeight: 700,
								textTransform: 'uppercase',
								fontFamily: 'Clash Grotesk Semibold',
								color: '#52575C',
							}}
						>
              				[{conditionShortner(condition)}] {weapon}
						</Typography>
						<Typography variant="body1" sx={{ fontWeight: 600 }}>
							{skinName}
						</Typography>
						<Box
							sx={{
								backgroundColor: 'background.neutral',
								borderRadius: '5px',
								width: '50%',
							}}
						>
							<Typography
								sx={{ textAlign: 'center', fontFamily: 'Clash Grotesk Medium' }}
							>
								{balance === false && 'Kr'} {(bought_price/100).toFixed(0)}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Stack>
		</Card>
	);
};

export default ChosenSkinCard;