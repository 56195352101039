import { Box, Card, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { doc } from 'firebase/firestore';
import React, { useRef } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { getUserInfoWithId } from '../../../api/queries';
import { db } from '../firebaseconfig';
import AdminChatBox from './AdminChatBox';
import SendMessageBox from './SendMessageBox';
const MainChat = ({ activeChatId }) => {
	const containerRef = useRef();
	const { data, isLoading } = useQuery(['activeChatter', activeChatId], () =>
		getUserInfoWithId(activeChatId)
	);

	const [status] = useDocumentData(doc(db, 'users', activeChatId));

	return (
		<Card sx={{ width: '100%', height: '70vh' }}>
			<Stack sx={{ width: '100%', height: '100%' }}>
				<Box
					sx={{
						backgroundColor: 'primary.light',
						margin: 2,
						borderRadius: '8px',
						flexDirection: 'row',
						display: 'flex',
						justifyContent: 'space-between',
						px: 2,
						height: '8%',
						alignItems: 'center'
					}}
				>
					{!isLoading && (
						<>
							<Typography variant='h6'>Kr {data.inventory_value}</Typography>
							<Typography variant='h6'>{data.steam_name}</Typography>
							<Typography variant='h6'>{data.name}</Typography>
							<Box
								component='a'
								sx={{
									height: '25px',
									color: 'text.primary',
									textTransform: 'capitalize'
								}}
								href={`https://steamcommunity.com/profiles/${activeChatId}`}
								target='_blank'
							>
                steam
							</Box>
						</>
					)}
				</Box>
				{status && (
					<Box
						sx={{
							height: '80%',
							width: '100%',
							px: 2,
							position: 'relative',
							overflowY: 'auto',
							overflowX: 'hidden',
							pb: 1
						}}
					>
						<AdminChatBox
							containerRef={containerRef}
							activeChatId={activeChatId}
							status={status}
						></AdminChatBox>
						<span ref={containerRef}></span>
					</Box>
				)}
				<Box sx={{ height: '12%', backgroundColor: '#fff' }}>
					<SendMessageBox steamid={activeChatId} containerRef={containerRef} />
				</Box>
			</Stack>
		</Card>
	);
};

export default MainChat;