import React, { useState } from 'react';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
	addDoc,
	collection,
	doc,
	serverTimestamp,
	setDoc
} from 'firebase/firestore';
import { db } from '../firebaseconfig';
const SendMessageBox = ({ steamid, containerRef }) => {
	const [message, setMessage] = useState('');
	const [isSending, setIsSending] = useState(false);
	const sendMessage = async (event) => {
		event.preventDefault();

		if(message.trim() === '') {
			alert('Enter valid message');

			return;
		}
		setIsSending(true);
		await setDoc(doc(db, 'users', steamid), {
			lastMessage: serverTimestamp(),
			hidden: false,
			adRead: true,
			userRead: false
		});
		await addDoc(collection(db, 'users', steamid, 'messages'), {
			text: message,
			createdAt: serverTimestamp(),
			fromAdmin: true
		});
		setMessage('');
		setIsSending(false);
		containerRef.current.scrollIntoView({ behavior: 'smooth' });
	};
	const handleChange = (event) => {
		setMessage(event.target.value);
	};
	const handleSubmit = (event) => {
		sendMessage(event);
	};

	return (
		<form className='send-message' onSubmit={handleSubmit}>
			<TextField
				sx={{ maxHeight: '90px', resize: 'vertical', overflow: 'auto' }}
				placeholder='Vennligst skriv inn en melding!'
				multiline
				value={message}
				onChange={handleChange}
				inputProps={{ style: { color: '#000000' } }}
				InputProps={{
					endAdornment: (
						<LoadingButton type='submit' loading={isSending && isSending}>
							<ArrowCircleRightIcon
								sx={{ transform: 'rotate(-90deg)', color: 'primary.main' }}
							/>
						</LoadingButton>
					)
				}}
				fullWidth
				onKeyPress={(ev) => {
					if(ev.key === 'Enter' && !ev.shiftKey) {
						ev.preventDefault();
						handleSubmit(ev);
					}
				}}
			/>
		</form>
	);
};

export default SendMessageBox;