import {
	Alert,
	Box,
	FormControlLabel,
	Stack,
	Switch,
	TextField,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
	getSettings,
	postUpdateBuyPercent,
	postUpdateStatus,
	postUpdateTextMessage,
	setBanner
} from '../api/queries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import BuyerAccount from './BuyerAccount';

const AdminStatuses = () => {
	const [checkbox, setCheckBox] = useState({ buy: null });
	const [alertMessage, setAlertMessage] = useState('');
	const [inpValue, setInpValue] = useState(0);
	const [newMessage,setNewMessage] = useState('');
	const [timerNewMessage, setTimerNewMessage] = useState(0);
	const { data,isLoading } = useQuery(['settings'], getSettings, {
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			setCheckBox((prev) => ({ ...prev, buy: data.status.buy }));
			setOriginalVal(data.status.buyPercent);
			setInpValue(data.status.buyPercent);
		}
	});
	useEffect(()=>{
		if(!isLoading){
			setNewTextMessage(data?.status?.textMelding || `Heisann!
    \n\nTakk for at du valgte å selge dine skins hos oss.
    \n\nVi har nå overført penger, og de bør være synlige på kontoen din innen 15 minutter.
    \n\nVi hadde satt stor pris på en trustpilot anmeldelse om du har mulighet:\nhttps://no.trustpilot.com/review/norskins.no 
    \n\nHa en god dag videre!
    \n\nMvh.\nNorskins`);
			setNewMessageTelegram(data?.status?.telegramMelding || `Heisann!
    \n\nTakk for at du valgte å selge dine skins hos oss.
    \n\nVi har nå overført penger, og de bør være synlige på kontoen din innen 15 minutter.
    \n\nVi hadde satt stor pris på en trustpilot anmeldelse om du har mulighet:\nhttps://no.trustpilot.com/review/norskins.no 
    \n\nHa en god dag videre!
    \n\nMvh.\nNorskins`);
		}
	},[data,isLoading]);
	const [newTextMessage, setNewTextMessage] = useState('loading');
	const [newMessageTelegram, setNewMessageTelegram] = useState(`Heisann!
    \n\nTakk for at du valgte å selge dine skins hos oss.
    \n\nVi har nå overført penger, og de bør være synlige på kontoen din innen 15 minutter.
    \n\nVi hadde satt stor pris på en trustpilot anmeldelse om du har mulighet:\nhttps://no.trustpilot.com/review/norskins.no 
    \n\nHa en god dag videre!
    \n\nMvh.\nNorskins`);
	const [orginalVal, setOriginalVal] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const [buttonLoading, setButtonLoading] = useState(false);
	const queryClient = useQueryClient();
	const updateSettingsMutation = useMutation(postUpdateStatus, {
		onSuccess: () => {
			queryClient.invalidateQueries('settings');
			enqueueSnackbar('Status Updated');
		}
	});
	const updateBuyPercentMutation = useMutation(postUpdateBuyPercent, {
		onSuccess: () => {
			queryClient.invalidateQueries('settings');
			enqueueSnackbar('Status Updated');
		}
	});
	const updateTextMessageMutation = useMutation(postUpdateTextMessage, {
		onSuccess: () => {enqueueSnackbar('Meldinger Updated');}
	});
	const setNewMessageMutation = useMutation(setBanner, {
		onSuccess:()=>{
			setNewMessage('');
			setTimerNewMessage(0);
			queryClient.invalidateQueries('settings');
			enqueueSnackbar('Melding Lagd/slettet!');
		}
	});
	
	
	const handleChange = async (method) => {
		try{
			if(Object.keys(checkbox).includes(method)) {
				updateSettingsMutation.mutate(method);
			}
		} catch(err) {
			enqueueSnackbar(`Noe gikk galt! ${err.message}`, {
				variant: 'error'
			});
		}
	};
	const handleInputChange = (inp) => {
		const inpFloat = parseFloat(inp);
		if(inpFloat > 1 || inpFloat < 0) {
			setAlertMessage('Input må være mellom 0 og 1');

			return;
		}
		setInpValue(inpFloat);
	};
	const handleButtonSubmit = () => {
		setButtonLoading(true);
		updateBuyPercentMutation.mutate(inpValue);
		setButtonLoading(false);
	};
	const handleChangeTextMessage = async () => {
		updateTextMessageMutation.mutate({newTextMessage,newMessageTelegram});
	};
	const handleNewMessage = () => {
		setButtonLoading(true);
		const status = 1;
		const inpDataTimer ={newMessage,status, timerNewMessage};
		setNewMessageMutation.mutate(inpDataTimer);
		setButtonLoading(false);
	};
	const handleDeleteMessage = () => {
		setButtonLoading(true);
		const status = 2;
		const inpDataTimer ={newMessage,status, timerNewMessage};
		setNewMessageMutation.mutate(inpDataTimer);
		setButtonLoading(false);
	};
	if(isLoading) {
		<p>Getting Status!!!</p>;
	}

	return (
		<Stack
			sx={{ justifyContent: 'center', width: '100%', alignItems: 'center' }}
		>
			<Box>
				<FormControlLabel
					checked={checkbox.buy}
					onChange={() => handleChange('buy')}
					control={<Switch color='primary' />}
					label='Endre Buy Status:'
					labelPlacement='start'
				/>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
				<TextField
					size='small'
					value={inpValue}
					type='number'
					label='Kjøps prosent'
					onChange={(event) => handleInputChange(event.target.value)}
				></TextField>
				{alertMessage !== '' && <Alert severity='error'>{alertMessage}</Alert>}
				{inpValue !== orginalVal &&
					inpValue &&
					!(inpValue > 1 || inpValue < 0) && (
					<LoadingButton
						variant='contained'
						onClick={handleButtonSubmit}
						loading={buttonLoading}
					>
              		Oppdater
					</LoadingButton>
				)}
			</Box>

			<Box sx={{mt:2}}>
				<TextField
					size='medium'
					value={newMessage}
					type='text'
					multiline
					rows={4}
					label='Ny melding'
					onChange={(event) => setNewMessage(event.target.value)}
				></TextField>
				<TextField type="number" value={timerNewMessage} label="Antall timer" onChange={(event)=>setTimerNewMessage(event.target.value)}></TextField>
				{newMessage !== '' && timerNewMessage!==0 &&(
					<LoadingButton
						variant='contained'
						onClick={handleNewMessage}
						loading={buttonLoading}
					>
			  		Legg ut melding
					</LoadingButton>
				)}
			</Box>
			<Box sx={{mt:2}}>
				<textarea
					name="postContent"
					rows={4}
					cols={40}
					value={newTextMessage}
          			onChange={e => setNewTextMessage(e.target.value)}
				></textarea>
				{newTextMessage !== ''&&(
					<LoadingButton
						variant='contained'
						onClick={handleChangeTextMessage}
					>
			  		Legg ut melding
					</LoadingButton>
				)}
			</Box>
			<Box sx={{mt:2}}>
				<Typography>Med trustpilot</Typography>
				<textarea
					name="telegram"
					rows={4}
					cols={40}
					value={newMessageTelegram}
          			onChange={e => setNewMessageTelegram(e.target.value)}
				></textarea>
				{newTextMessage !== ''&&(
					<LoadingButton
						variant='contained'
						onClick={handleChangeTextMessage}
					>
			  		Legg ut melding
					</LoadingButton>
				)}
			</Box>
			{!isLoading && data.banner &&(
				<Box sx={{mt:2,display:'flex',flexDirection:'column',gap:2}}>
					<Typography variant="subtitle1" textAlign={'center'}>Banner</Typography>
					<Alert severity='info'>{data.banner}</Alert>
					<LoadingButton
						variant='contained'
						onClick={handleDeleteMessage}
						loading={buttonLoading}
					>
			  		Slett melding
					</LoadingButton>
				</Box>
			)}
			<BuyerAccount/>
		</Stack>
	);
};

export default AdminStatuses;