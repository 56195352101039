import { createContext, useState } from 'react';

const ShoppingCartContext = createContext({});

//Skin InstanceID

const ShoppingCartProvider = ({ children }) => {
	const [cartItems, setCartItems] = useState([]);
	const [inventory, setInventory] = useState([]);
	const [activeDeals, setActiveDeals] = useState([]);
	const [activeDeal, setActiveDeal] = useState([]);
	const [menu, setMenu] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [id, setId] = useState(0);
	function emptyCart() {
		setCartItems([]);
	}
	function getTotalPrice() {
		if(cartItems.length > 0) {
			return cartItems.reduce((prev, curr) => prev + curr.bought_price/100, 0);
		} else{
			return 0;
		}
	}
	function handleClick(item) {
		if(cartItems.filter((e) => e.assetid === item.assetid).length > 0) {
			return setCartItems(cartItems.filter((e) => e.assetid !== item.assetid));
		} else{
			setCartItems((currItems) => {
				return [...currItems, item];
			});
		}
	}
	function getActiveDeals() {
		return activeDeals;
	}
	function getUniqueDeals() {
		return [...new Set(activeDeals.map((item) => item.deal_id))];
	}
	
	return (
		<ShoppingCartContext.Provider
			value={{
				getTotalPrice,
				handleClick,
				cartItems,
				emptyCart,
				setInventory,
				inventory,
				getActiveDeals,
				setActiveDeals,
				setActiveDeal,
				activeDeal,
				getUniqueDeals,
				menu,
				setMenu,
				openModal,
				setOpenModal,
				id,
				setId,
			}}
		>
			{children}
		</ShoppingCartContext.Provider>
	);
};
export{ ShoppingCartContext, ShoppingCartProvider };