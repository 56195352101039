import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SkeletonProductItem } from '../../components/skeleton';
import { Box, Typography } from '@mui/material';
import SkinCard from './SkinCard';
import { ShoppingCartContext } from '../../contexts/ShoppingCartContext';
ShopProductList.propTypes = {
	itemList: PropTypes.array,
	loading: PropTypes.bool,
};
export default function ShopProductList({
	itemList,
	isLoading,
	isFetching,
	balance,
}) {
	const { handleClick, cartItems, getTotalPrice } = useContext(ShoppingCartContext);
	if(itemList === 'Steam is down, try again later!') {
		return <Typography>Steam is down, try again later!</Typography>;
	}
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				gap: 2,
				mt: 2,
				pb: 2,
				justifyContent: 'center',
			}}
		>
			{(isLoading || isFetching ? [...Array(21)] : itemList).map(
				(product, index) =>
					product ? (
						<SkinCard
							getTotalPrice={getTotalPrice}
							balance={balance}
							cartItems={cartItems}
							product={product}
							index={index}
							key={index}
							handleClick={handleClick}
						/>
					) : (
						<SkeletonProductItem key={index} />
					)
			)}
		</Box>
	);
}