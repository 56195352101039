import { Container, Typography } from '@mui/material';
import React from 'react';
import ItemSelector from './ItemSelector';
import BannedItemsList from './BannedItemsList';

const BanningItems = () => {
	return (
		<Container disableGutters maxWidth='lg' sx={{display:'flex',gap:2, flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
			<Typography variant='h3'>Velkommen til verdens beste banning side!</Typography>
			<ItemSelector/>
			<Typography variant='h4'>Banned items</Typography>
			<BannedItemsList/>
		</Container>
	);
};

export default BanningItems;