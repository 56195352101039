import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { ShoppingCartContext } from '../../../contexts/ShoppingCartContext';
import ChosenSkinCard from '../../Sell/step1/ChosenSkinCard';
import *as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTradelink, sendCashoutRequestQuery } from '../../../api/queries';
import { useNavigate } from 'react-router-dom';
const ChooseSkinsCashout = ({ balance }) => {
	const { getTotalPrice, cartItems, emptyCart } = useContext(ShoppingCartContext);
	const navigate = useNavigate();
	const [checked, setChecked] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [tradelink, setTradelink] = useState('');
	const [error, setError] = useState('');
	useQuery(['tradelink'], getTradelink, {
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			setTradelink(data);
		}
	});
	const tradelinkSchema = Yup.object().shape({
		tradelink: Yup.string()
			.required('CodeIsRequired')
			.test('Check Prefix', function () {
				return !!tradelink.startsWith(
					'https://steamcommunity.com/tradeoffer/new/?partner='
				);
			})
	});
	const handleChange = (event) => {
		setTradelink(event.target.value);
	};
	const queryClient = useQueryClient();
	const cashoutUpdateMutation = useMutation(sendCashoutRequestQuery, {
		onSuccess: () => {
			queryClient.invalidateQueries('cashoutDeals');
		}
	});
	const handleSubmit = () => {
		tradelinkSchema
			.validate({ tradelink })
			.then(function (valid) {
				if(valid) {
					cashoutUpdateMutation.mutate({ cartItems:cartItems.map((skin)=>skin.assetid), tradelink });
					setError('');
					emptyCart();
					enqueueSnackbar('Success');
					navigate('/referrals/dashboard');
				}
			})
			.catch(function (err) {
				console.log(err);
				setError('Må være en tradelink');
			});
	};

	return (
		<Stack sx={{ height: '100%' }}>
			<Box
				sx={{
					minHeight: { xs: '50%', md: '68%', xl: '80%' },
					overflow: 'auto'
				}}
			>
				{cartItems.map((item, index) => {
					return (
						<ChosenSkinCard
							key={item.assetid}
							index={index}
							item={item}
							balance={balance}
						></ChosenSkinCard>
					);
				})}
			</Box>
			<Divider></Divider>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10%' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						px: 2,
						pt: 2,
						alignItems: 'center'
					}}
				>
					<Typography
						variant='body1'
						sx={{
							fontSize: '1.125rem',
							lineHeight: 1.375,
							color: 'text.secondary'
						}}
					>
            Total Pris:
					</Typography>
					<Box>
						<Typography
							variant='h3'
							sx={{
								lineHeight: '1.875rem',
								fontFamily: 'Clash Grotesk Medium'
							}}
						>
							{getTotalPrice().toFixed(0)}/{balance}
						</Typography>
						{getTotalPrice() > balance && (
							<Typography sx={{ fontSize: 10, color: 'error.main' }}>
                				Du har ikke råd til dette
							</Typography>
						)}
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						height: '100%',
						alignItems: 'center'
					}}
				>
					<TextField
						size='small'
						label='Tradelink'
						value={tradelink}
						onChange={handleChange}
						helperText={error}
						error={error !== ''}
					></TextField>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={checked}
									onChange={() => setChecked(!checked)}
								/>
							}
							label={
								<Typography sx={{ fontFamily: 'sans-serif' }}>
                  						Jeg har lest og akseptert{' '}
									<Typography
										display='inline'
										sx={{ fontWeight: 700, color: '#fff' }}
										component={'a'}
										href='/'
									>
                    					vilkår
									</Typography>
								</Typography>
							}
						/>
					</FormGroup>

					<Button
						variant='contained'
						color='primary'
						size='large'
						onClick={handleSubmit}
						disabled={
							!cartItems.length > 0 ||
							!checked ||
							getTotalPrice() > balance ||
							!tradelink.startsWith(
								'https://steamcommunity.com/tradeoffer/new/?partner='
							)
						}
					>
            Cashout
					</Button>
				</Box>
			</Box>
		</Stack>
	);
};

export default ChooseSkinsCashout;