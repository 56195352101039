import { Container } from '@mui/material';
import React from 'react';
import { ShoppingCartProvider } from '../../../contexts/ShoppingCartContext';
import ReferralCashoutShop from './ReferralCashoutShop';

const ReferralCashout = () => {
	return (
		<>
			<ShoppingCartProvider>
				<Container maxWidth={'xl'} sx={{ pt: 5 }}>
					<ReferralCashoutShop/>
				</Container>
			</ShoppingCartProvider>
		</>
	);
};

export default ReferralCashout;