// ----------------------------------------------------------------------

export default function Link() {
	return {
		MuiLink: {
			defaultProps: {
				underline: 'hover',
			},
		},
	};
}