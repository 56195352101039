import axios from '../utils/axios';

// users
export async function getItems(id,force) {
	let data = null;
	if(id === 0) {
		if(force){
			data = await axios.get('user/userinventory?force=true');
		}
		else{
			data = await axios.get('user/userinventory');
		}
	} else if(id === 3) {
		data = await axios.get('user/userinventory?type=manuell');
	} else{
		data = await axios.get('user/referralInventory');
	}

	return data.data;
}

export async function getLastDeals(startDate, endDate) {
	const data = await axios.get(
		`admin/downloads/lastDeals?startDate=${startDate}&endDate=${endDate}`
	);

	return data.data;
}
export async function getBuyerInventory() {
	const data = await axios.get('admin/getBuyerInventory');

	return data.data;
}
export async function getSettings() {
	const data = await axios.get('site/siteSettings');

	return data.data;
}
export async function postUpdateBuyPercent(inp) {
	const data = await axios.post(`admin/updateBuyPercent/${inp}`);
	if(data.status !== 200) {
		return data.status;
	}

	return data.data;
}
export async function postUpdateStatus(method) {
	const data = await axios.post(`admin/updateSettings/${method}`);
	if(data.status !== 200) {
		return data.status;
	}

	return data.data;
}
export async function getUserInfo() {
	const data = await axios.get('user/userDetails');

	return data.data;
}

export async function getUserInfoWithId(steamid) {
	const data = await axios.get(`user/getUserInfo/${steamid}`);

	return data.data;
}
export async function getAllUserInfoWithId(steamid) {
	const data = await axios.get(`user/getAllUserInfo/${steamid}`);

	return data.data;
}
export async function newMessageNotification() {
	const data = await axios.post('user/newMessage');

	return data.status;
}
export async function getCashoutOffers({
	page,
	rowsPerPage,
	orderBy = 'created_at',
	order = 'DESC',
}) {
	const { data } = await axios.get(
		`referrals/referralsCashoutsId?page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&order=${order}`
	);

	return data;
}
export async function getAllReferralStats() {
	const { data } = await axios.get('referrals/allReferralStatsForUser');

	return data;
}

export async function postAddOrder(inpData) {
	const data = await axios.post('orders/postAddOrder', null, {
		params: {
			inpData,
		},
	});

	return data;
}
export async function postAddManuellOrder(inpData) {
	const data = await axios.post('orders/postAddManuellOrder', null, {
		params: {
			inpData,
		},
	});

	return data;
}
export async function getAllInvs({
	page,
	rowsPerPage,
	orderBy = 'last_updated',
	order = 'DESC',
}) {
	const data = await axios.get(
		`admin/getAllInvValues?page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&order=${order}`
	);

	return data.data;
}

// Giveaways
export async function getAllGiveaways() {
	const data = await axios.get('giveaway/giveaways');

	return data;
}

export async function postJoinGiveaway(giveawayInfo) {
	const data = await axios.post('giveaway/joingiveaway', null, {
		params: {
			...giveawayInfo,
		},
	});

	return data;
}
// Deals
export async function getDeals({
	page,
	rowsPerPage,
	filterStatus = 'all',
	orderBy = 'createdAt',
	order = 'DESC',
	filterName = '',
}) {
	const data = await axios.get(
		`admin/getAllOrders?page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&order=${order}&filterStatus=${filterStatus}&filterName=${filterName}`
	);

	return data.data;
}
export async function getDealsSummary() {
	const data = await axios.get('admin/getAllOrders/summary');

	return data.data;
}
export async function getDeal(dealId) {
	const { data } = await axios.get(`admin/getOrder/${dealId}`);

	return data;
}
export async function getDealCompleteTrade(dealId) {
	const { data } = await axios.get(`orders/getDealCompleteTrade/${dealId}`);

	return data;
}
export async function getBuyerAccount(){
	const { data } = await axios.get('site/buyerAccount');

	return data;
}
export async function putUpdateBuyAccount(account){
	const { data } = await axios.put('site/updateBuyAccount',
		null, {
			params: {
				account
			},
		});

	return data;
}

export async function postSendMail(mailInfo) {
	const data = await axios.post('sendMail', null, {
		params: {
			...mailInfo,
		},
	});

	return data;
}

export async function getRefInfo() {
	const data = await axios.get('referrals/userRefInfo');

	return data;
}
export async function getAllCodes() {
	const data = await axios.get('referrals/getAllCodesForVerify');

	return data;
}
export async function getTradeStatus() {
	const data = await axios.get('admin/getActiveTrades');

	return data;
}
export async function updateRefCode(inpData) {
	const data = await axios.post(
		`referrals/postUpdateRefCode?newCode=${inpData}`
	);

	return data;
}
export async function getAllRefForUser() {
	const data = await axios.get('referrals/allReferralsForUser');

	return data;
}
export async function getReferralTransactionsForUser({
	page,
	rowsPerPage,
	orderBy = 'createdAt',
	order = 'DESC',
}) {
	const data = await axios.get(
		`referrals/getReferralTransactionsForUser?page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&order=${order.toUpperCase()}`
	);

	return data;
}

export async function setBanner(inpData) {
	const { newMessage, status, timerNewMessage } = inpData;

	const data = await axios.post('site/postSetBanner', null, {
		params: {
			message: newMessage,
			status,
			expire: timerNewMessage,
		},
	});

	return data.data;
}

export async function postUpdateTextMessage(input){
	const data = await axios.put('admin/updateTextMessage', {
		vanligMelding:input.newTextMessage, // send data in the body
		telegramMelding:input.newMessageTelegram
	});

}

export async function updateDealQuery(
	bought_price,
	price_usd,
	items,
	id,
	status,
	boughtby,
	soldDate
) {
	const data = await axios.put('admin/putUpdateOrder', null, {
		params: {
			bought_price,
			price_usd,
			items,
			id,
			status,
			boughtby,
			soldDate,
		},
	});

	return data;
}

export async function getDealsForUser({
	page,
	rowsPerPage,
	orderBy = 'createdAt',
	order = 'DESC',
}) {
	const { data } = await axios.get(
		`user/allUserDeals?page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&order=${order.toUpperCase()}`
	);

	return data;
}
export async function getUserIds() {
	const { data } = await axios.get('admin/getUserIds');

	return data;
}
export async function postDeleteDeal(id) {
	const data = await axios.post(`admin/postDeleteOrder/${id.id}`);

	return data.data;
}
export async function postDeleteSkinFromDeal(assetId) {
	try{
		const data = await axios.post(`admin/postDeleteSkinFromDeal/${assetId}`);
		
		return data.data;
	}
	catch(err){
		console.log(err.response.status);
		
		return {status: err.response.status, message: err.message};
	}
}
// Admin

// Gets
export async function getProfits() {
	const data = await axios.get('admin/profits');

	return data.data;
}
export async function getVolume() {
	const data = await axios.get('admin/volume');

	return data.data;
}
export async function getBuys({
	page,
	rowsPerPage,
	type = 'all',
	orderBy = 'createdAt',
	order = 'DESC',
	filterName = '',
}) {
	const data = await axios.get(
		`admin/getAllBuys?page=${page}&limit=${rowsPerPage}&type=${type}&orderBy=${orderBy}&order=${order.toUpperCase()}&filterName=${filterName}`
	);

	return data.data;
}
export async function getBuysSummary() {
	const data = await axios.get('admin/getAllBuys/summary');

	return data.data;
}
export async function getBalance() {
	const data = await axios.get('referrals/getUserbalance');

	return data.data;
}
export async function getTradelink() {
	const { data } = await axios.get('referrals/getUserTradelink');

	return data;
}
export async function searchForItems() {
	const data = await axios.get('admin/getAllItems');

	return data.data;
}
export async function getCashoutsAdmin() {
	const data = await axios.get('admin/getAllReferralCashouts');

	return data.data;
}
export async function getReferralsAdminsBalances() {
	const data = await axios.get('ref/adminreferral');

	return data.data;
}
export async function putUpdateReferralBalance(inpData) {
	const { data } = await axios.put('ref/updateBalance', null, {
		params: {
			inpData,
		},
	});

	return data;
}
export async function getCashoutId(cashoutId) {
	const { data } = await axios.get(`admin/getReferralCashout/${cashoutId}`);

	return data;
}
export async function getBannedItems() {
	const { data } = await axios.get('admin/getBannedItems');

	return data;
}
// Put
export async function putUpdateCashout(inpData) {
	const { data } = await axios.put('admin/updatecashout', null, {
		params: {
			inpData,
		},
	});

	return data;
}

export async function putUpdateTradelink(inpData) {
	const { data } = await axios.put('user/putUpdateTradelink', null, {
		params: {
			inpData,
		},
	});

	return data;
}

export async function putUpdateProfit(inpData) {
	const { data } = await axios.put('orders/updateProfits', null, {
		params: {
			inpData,
		},
	});

	return data;
}
export async function putUpdateBuys(ids) {
	const { data } = await axios.put('admin/putUpdateBuys', null, {
		params: {
			ids,
		},
	});

	return data;
}

// Post
export async function postBanItems(inpData) {
	const { data } = await axios.post('admin/postBanItems', null, {
		params: {
			inpData,
		},
	});
	
	return data;
}
export async function postUnbanItem(item) {
	const { data } = await axios.post('admin/postUnbanItem' + '/' + item);

	return data;
}
/* export async function updateIdQuery(newId) {
  const data = await axios.post(`user/updateId/${newId}`);
  return data.status;
} */

export async function sendCashoutRequestQuery(input) {
	const data = await axios.post('referrals/postReferralCashout', null, {
		params: {
			input,
		},
	});

	return data.data;
}
export async function postUpdateTradelink(tradelink){
	const data = await axios.post('referrals/updateTradelink', null, {
		params: {
			tradelink,
		},
	});

	return data.data;
}
export async function setRefCodeQuery(refCode) {
	const data = await axios.post('user/postSetReferral', null, {
		params: {
			refCode,
		},
	});

	return data.status;
}
export async function postDeleteCashout(id) {
	const data = await axios.post(`ref/deletecashout/${id.id}`);

	return data.data;
}