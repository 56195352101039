import { Box, Card } from '@mui/material';
import React from 'react';
import ChooseSkinsCashout from './ChooseSkinsCashout';

const ReferralCashoutInfo = ({ balance }) => {
	return (
		<Card sx={{ height: '90%' }}>
			<Box sx={{ height: '80%' }}>
				<ChooseSkinsCashout balance={balance} />
			</Box>
		</Card>
	);
};

export default ReferralCashoutInfo;