import React from 'react';

function Steamicon() {
	return (
		<svg viewBox="0 0 20 20" width={'30px'} xmlns="http://www.w3.org/2000/svg" className="csm_ui__size_20__2e8eb" aria-label="social steam" fill='white'>
			<path d="M17.548 7.942c-.049.687-.694 1.434-1.57 1.374-.687-.047-1.391-.712-1.325-1.57.049-.639.645-1.385 1.521-1.326.876.06 1.424.835 1.374 1.522zm-1.521 2.797l-2.602 1.914c0 .147-.032.324-.049.393-.351 1.422-1.374 1.767-2.012 1.767-1.08-.05-1.816-.639-2.11-1.668L4.51 11.184c-.394.253-.89.355-1.397.32-.964-.066-1.985-.973-1.839-2.354.106-1.004 1.063-1.866 2.124-1.794 1.06.073 1.764.794 1.927 1.61l4.812 1.97c.393-.245.638-.343 1.227-.343l1.867-2.7c.001-.041-.005-.212 0-.292.106-1.54 1.47-2.702 3.048-2.594a2.871 2.871 0 012.693 2.984c-.05 1.62-1.424 2.798-2.945 2.748zM4.767 8.733c-.236-.487-.676-.854-1.37-.9-.657-.046-1.522.43-1.642 1.317a1.628 1.628 0 001.358 1.838c.159.026.48.035.838-.06l-1.182-.49c-.473-.24-.611-.802-.344-1.471.361-.563.92-.91 1.807-.45l.534.216zm9.444-1.028c-.081 1.197.811 1.991 1.718 2.053.883.06 2.029-.57 2.11-1.767.05-.932-.59-1.978-1.816-2.061-1.276-.05-2.012.981-2.012 1.775zM12.886 12.9c.134-.98-.718-1.816-1.522-1.816-.13-.01-.441 0-.638.098l1.151.458c.578.355.714 1.112.393 1.66a1.248 1.248 0 01-1.7.378l-.727-.288c.294.59 1.008.933 1.521.933.513 0 1.371-.327 1.522-1.423z">
			</path>
		</svg>
	);
}

export default Steamicon;