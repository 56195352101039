import React, { useState } from 'react';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {
	addDoc,
	collection,
	doc,
	serverTimestamp,
	setDoc
} from 'firebase/firestore';
import useAuth from '../../../hooks/useAuth';
import { db } from '../firebaseconfig';
import { newMessageNotification } from '../../../api/queries';
import { useSnackbar } from 'notistack';

const SendMessageUser = ({ setNotifyAdmins, notifyAdmins }) => {
	const { enqueueSnackbar } = useSnackbar();
	const styles = {
		main: {
			height: '100%',
			position: 'relative'
		},
		wrapper: {
			height: '100%',
			position: 'relative'
		},
		textArea: {
			height: '100%',
			overflowY: 'auto',
			width: '100%',
			backgroundColor: '#fff',
			resize: 'none',
			border: 'none',
			outline: 'none',
			padding: '10px 50px 10px 10px',
			fontSize: '15px'
		}
	};
	const { user, isAuthenticated } = useAuth();
	const [message, setMessage] = useState('');
	const onEnterPress = (e) => {
		if(e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			handleSubmit(e);
		}
	};
	const sendMessage = async (event) => {
		event.preventDefault();
		if(message.trim() === '') {
			alert('Enter valid message');

			return;
		}
		await setDoc(doc(db, 'users', user.id), {
			lastMessage: serverTimestamp(),
			adRead: false,
			userRead: true,
			hidden: false
		});
		await addDoc(collection(db, 'users', user.id, 'messages'), {
			text: message,
			createdAt: serverTimestamp(),
			fromAdmin: false
		});
		if(notifyAdmins) {
			const result = await newMessageNotification();
			if(result===200){
				enqueueSnackbar('Admins notified');
			}
		}
		
		setMessage('');
		setNotifyAdmins(false);
		// scrollRef.current.scrollIntoView({ behavior: 'smooth' });
	};
	const handleSubmit = (event) => {
		sendMessage(event);
	};

	return isAuthenticated
		? (
			<form className='send-message' onSubmit={handleSubmit} style={styles.main}>
				<div style={styles.wrapper}>
					<textarea
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						placeholder='Skriv inn din melding!'
						spellCheck='false'
						data-gramm='false'
						data-gramm_editor='false'
						data-enable-grammarly='false'
						style={styles.textArea}
						onKeyDown={onEnterPress}
					></textarea>
				</div>
				<button
					type='submit'
					disabled={message === ''}
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						outline: 'none',
						background: 'none',
						color: 'inherit',
						border: 'none',
						padding: 0,
						font: 'inherit',
						cursor: 'pointer'
					}}
				>
					<ArrowCircleRightIcon
						color={message === '' ? 'disabled' : 'primary'}
						sx={{
							transform: 'rotate(-90deg)',
							width: '50px',
							height: '50px'
						}}
					/>
				</button>
			</form>
		)
		: (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%'
				}}
			>
				<p style={{ textAlign: 'center', fontSize: '20px' }}>
        Vennligst logg inn!
				</p>
			</div>
		);
};

export default SendMessageUser;