import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { SettingsProvider } from './contexts/SettingsContext';
import { HelmetProvider } from 'react-helmet-async';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AuthProvider>
		<HelmetProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<SettingsProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</SettingsProvider>
			</LocalizationProvider>
		</HelmetProvider>
	</AuthProvider>
);