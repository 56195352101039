import { Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getBannedItems, postUnbanItem } from '../../../api/queries';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
const BannedItemsList = () => {
	const {isLoading, data} = useQuery(['bannedItems'],getBannedItems );
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();
	const mutation = useMutation(postUnbanItem, {
		onSuccess: () => {
			queryClient.invalidateQueries('bannedItems');
			setSelectedItems([]);
			enqueueSnackbar('Item unBanned');
		},
		onError: (error) => {
		// Handle any error that occurred during the mutation
			console.error('Mutation error:', error);
		},
	});
	if(isLoading) return <p>Loading..</p>;

	
	const handleButtonClick = (item) => {
		mutation.mutate(item);
	};

	return (
		<Card>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							Item Name
						</TableCell>
						<TableCell>
							Remove
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((item) => (
						<TableRow key={item.item_id}>
							<TableCell>
								{item.item_id}
							</TableCell>
							<TableCell align='center'>
								<DeleteIcon onClick={()=>{handleButtonClick(item.item_id);}} sx={{'&:hover':{color:'primary.main',cursor:'pointer'}}}/>
							</TableCell>
						</TableRow>
					
					))}
				</TableBody>
			</Table>
		</Card>

	);
};

export default BannedItemsList;