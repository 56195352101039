import { Button, Typography } from '@mui/material';
import React from 'react';
import { CSVLink } from 'react-csv';
import { fDate } from '../../../utils/formatTime';
const csv_headers = [
	{ label: 'navn', key: 'name' },
	{ label: 'adresse', key: 'adresse' },
	{ label: 'postnr og poststed', key: 'postnr' },
	{ label: 'dato', key: 'created_date' },
	{ label: 'pris', key: 'bought_price' },
	{ label: 'skin', key: 'skin' },
	{ label: 'kontonr', key: 'kontonr' },
	{ label: 'email', key: 'email' },
	{ label: 'telefon', key: 'telefon' },
];
const ConvertToCsv = ({ offers }) => {
	console.log(offers);
	const csvData = offers.map((offer) => {
		const resData = {
			name: offer.name,
			adresse: offer.addresse.split(',')[0],
			postnr: `${offer?.postnr
				? offer.postnr.toString().length === 4
					? '"' + offer.postnr.toString() + '"'
					: '"0' + offer.postnr.toString() + '"'
				: 0} ${offer.postSted}`,
			created_date: fDate(offer.created_date),
			email: offer.email,
			bought_price: 'kr ' + offer.deal_bought_price,
			kontonr: offer.kontonr,
			skin: offer.most_expensive_item_name,
			telefon: offer.telefon,
		};

		return {
			...resData
		};
	});

	return (
		<>
			<Typography>Lengde:{offers.length}</Typography>
			<Button variant="contained" sx={{p:0}}>
				<CSVLink
					style={{ color: 'white',margin:10,width:'100%' }}
					data={csvData}
					headers={csv_headers}
					enclosingCharacter={''}
				>
      				Last ned
				</CSVLink>
			</Button>
		</>
	);
};

export default ConvertToCsv;