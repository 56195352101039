import styled from '@emotion/styled';
import { alpha, Box, Stack, Typography } from '@mui/material';
import { AnimatePresence, m } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { varZoom } from '../../../animate';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendMessageUser from './SendMessageUser';
import useAuth from '../../../hooks/useAuth';
import Status from './Status';
const RootStyle = styled(m.div)(({ theme }) => ({
	right: '10%',
	bottom: 0,
	display: 'flex',
	position: 'fixed',
	width: '80%',
	height: '90%',
	zIndex: theme.zIndex.drawer + 3,
	[theme.breakpoints.up('md')]: {
		right: '2.5%',
		width: '22%',
		height: '55%'
	},
	boxShadow: `-24px 12px 32px -4px ${alpha(
		theme.palette.mode === 'light'
			? theme.palette.grey[500]
			: theme.palette.common.black,
		0.16
	)}`
}));

const ChatPublic = () => {
	const { user, isAuthenticated } = useAuth();
	const [open, setOpen] = useState(false);
	const scrollRef = useRef();
	const [notifyAdmins, setNotifyAdmins] = useState(true);

	const varSideBar = open
		? varZoom({
			distance: 100,
			durationIn: 0.42,
			durationOut: 0.42
		}).inRight
		: varZoom({
			distance: 100,
			durationIn: 0.12,
			durationOut: 0.12
		}).outRight;

	return (
		<>
			<AnimatePresence>
				{open && (
					<>
						<RootStyle {...varSideBar}>
							<Box
								sx={{
									position: 'absolute',
									right: { xs: -25, md: -30 },
									top: 0
								}}
							>
								<HighlightOffIcon
									sx={{ cursor: 'pointer' }}
									onClick={() => setOpen(false)}
								/>
							</Box>
							<Stack sx={{ width: '100%', height: '100%' }}>
								<Box
									sx={{
										backgroundColor: 'primary.light',
										height: '10%',
										pl: 2
									}}
								>
									<Typography variant='h6'>Skriv inn din melding</Typography>
									<Typography variant='body1' sx={{ fontSize: '16px' }}>
                    Vi svarer vanligvis i løpet av noen få minutter
									</Typography>
								</Box>
								{isAuthenticated && (
									<>
										<Box
											sx={{
												width: '100%',
												height: '70%',
												backgroundColor: '#282639',
												overflowY: 'auto',
												overflowX: 'hidden',
												px: 2,
												pb: 1
											}}
										>
											<Status
												scrollRef={scrollRef}
												open={open}
												chatBox={true}
												user={user}
												setNotifyAdmins={setNotifyAdmins}
											/>
											<span ref={scrollRef}></span>
										</Box>
										<Box
											sx={{
												width: '100%',
												height: '20%',
												backgroundColor: '#3A384C'
											}}
										>
											<SendMessageUser
												notifyAdmins={notifyAdmins}
												setNotifyAdmins={setNotifyAdmins}
											/>
										</Box>
									</>
								)}
								{!isAuthenticated && (
									<>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												height: '100%',
												backgroundColor: '#3A384C'
											}}
										>
											<p style={{ textAlign: 'center', fontSize: '20px' }}>
                        Vennligst logg inn!
											</p>
										</div>
									</>
								)}
							</Stack>
						</RootStyle>
					</>
				)}
			</AnimatePresence>
			{!open && (
				<>
					{isAuthenticated && <Status user={user} />}
					<Box
						onClick={() => setOpen(true)}
						sx={{
							cursor: 'pointer',
							backgroundColor: 'primary.main',
							height: '210px',
							width: '35px',
							writingMode: 'vertical-lr',
							boxShadow: '-2px -1px 4px rgba(255, 255, 255, 0.25)',
							borderRadius: '15px 0px 0px 10px;',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<Typography
							sx={{ textAlign: 'center', transform: 'rotate(180deg)' }}
							variant='h4'
						>
              CHAT MED OSS!
						</Typography>
					</Box>
				</>
			)}
		</>
	);
};

export default ChatPublic;