import { Button, Stack } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
const BotLayout = () => {
	const { logout } = useAuth();
	const handleLogout = async () => {
		try{
			await logout();
		}
		catch(err) {
			console.log(err);
		}
	};
	
	return (
		<Stack>
			<Outlet />
			<Button onClick={handleLogout}>Logout</Button>
		</Stack>


	);
};

export default BotLayout;