import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
const Footer = () => {
	const d = new Date();
	let year = d.getFullYear();
	const isDesktop = useResponsive('up', 'md');
	const pages = [{ path: '/privacy-policy', label: 'Privacy policy' }, { path: '/kontakt', label: 'Kontakt' }, { path: '/tos', label: 'Brukervilkår' },];
	
	return (
		<AppBar sx={{ position: 'static' }}>
			<Toolbar sx={{ height: '55px' }}>
				<Stack direction={'row'} alignItems="center" justifyContent={isDesktop ? 'space-between' : 'center'} sx={{ width: '70%', px: '10%' }}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}><Box sx={{ height: 50 }} component="img" src="/logo.png" alt='Bilde av Norskins sin logo' /><Typography variant="subtitle2" sx={{ lineHeight: '150%', color: '#717782' }} >{isDesktop && 'Copyright ©' + year + ' Norskins.com.'}</Typography></Box>
					{isDesktop &&
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        	{pages.map((page) => <Typography component={Link} to={page.path} key={page.path} sx={{
                        		textDecoration: 'none',
                        		':hover': { color: 'primary.main' },
                        		color: '#fff',
                        	}}>{page.label}</Typography>)}
                        </Box>
					}
					{/* {isDesktop && <Typography>Happy trading {":)"}</Typography>} */}
				</Stack>
			</Toolbar>
		</AppBar >
	);
};

export default Footer;