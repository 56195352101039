import { Container, Grid } from '@mui/material';
import React, { useState } from 'react';
import MainChat from './MainChat';
import Rooms from './Rooms';
import useResponsive from '../../../hooks/useResponsive';
import ChatDrawer from './ChatDrawer';

const ChatRoom = () => {
	const [activeChatId, setActiveChatId] = useState(null);
	const [chats, setChats] = useState(null);
	const isDesktop = useResponsive('up', 'md');

	return (
		<Container maxWidth='lg' sx={{ mt: 2 }}>
			<Grid container justifyContent='space-between' spacing={10}>
				<Grid item xs={12} md={10}>
					{activeChatId !== null && <MainChat activeChatId={activeChatId} />}
				</Grid>
				<Grid item xs={!isDesktop ? 4 : 2}>
					{!isDesktop
						? (
							<>
								<ChatDrawer
									chats={chats}
									setChats={setChats}
									setActiveChatId={setActiveChatId}
									activeChatId={activeChatId}
								/>
							</>
						)
						: (
							<Rooms
								setActiveChatId={setActiveChatId}
								activeChatId={activeChatId}
							></Rooms>
						)}
				</Grid>
			</Grid>
		</Container>
	);
};

export default ChatRoom;