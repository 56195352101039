import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postBanItems, searchForItems } from '../../../api/queries';
import { matchSorter } from 'match-sorter';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';

const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue,).slice(0, 10);
const ItemSelector = () => {
	const { data,isLoading } = useQuery(['allItems'], ()=>searchForItems(), {
		refetchOnWindowFocus: false
	});
	const [selectedItems, setSelectedItems] = useState([]);
	const [value, setValue] = useState('');
  	const [inputValue, setInputText] = useState('');
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const mutation = useMutation(postBanItems, {
		onSuccess: () => {
			queryClient.invalidateQueries('bannedItems');
			setSelectedItems([]);
			enqueueSnackbar('Items Banned');
		},
		onError: (error) => {
		// Handle any error that occurred during the mutation
			console.error('Mutation error:', error);
		},
	});

	const handleButtonClick = () => {
		const dataToSend = selectedItems;
		mutation.mutate(dataToSend);
	};
	if(isLoading) return null;

	return (
		<Stack spacing={2}>
			<Box sx={{display:'flex',gap:2}}>
				<Autocomplete 
					sx={{ width: 300 }} 
					getOptionLabel={(option)=>option}
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputText(newInputValue);
					}} 
					options={data} 
					filterOptions={filterOptions} 
					renderInput={(params) => <TextField {...params} label="Custom filter" /> 
					}/>
				<Button variant='contained' size="large" onClick={()=>{
					setSelectedItems([...selectedItems, value]);
					setValue('');
				}}>
				Legg til
				</Button>
			</Box>
			{selectedItems.length>0 &&
			<Box>
				<Typography>Liste Over Valgte items</Typography>
				<Box sx={{display:'flex', flexDirection:'column',gap:0.5,border:'solid',px:2}}>
					{selectedItems.map((item)=>{
						return <Box key={item} sx={{position:'relative'}}>
							<Typography >{item}</Typography>
							<DeleteIcon onClick={()=>setSelectedItems(selectedItems.filter(itemInArray => itemInArray !== item))} sx={{position:'absolute',right:0,top:0,zIndex:2,'&:hover':{color:'primary.main',cursor:'pointer'}}}/>
						</Box>;
					})}
				</Box>
				<Button onClick={handleButtonClick} disabled={!selectedItems.length>0} sx={{mt:2}} variant='contained'>Ban Items</Button>
			</Box>}
		</Stack>
	);
};

export default ItemSelector;