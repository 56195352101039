import { Box, Card, Container, Typography } from '@mui/material';
import Page from '../../../components/Page';
import { useQuery } from '@tanstack/react-query';
import { getBuyerInventory } from '../../../api/queries';
import { fDate, fDateTime } from '../../../utils/formatTime';

const BuyerInv = () => {
	const {isLoading, data} = useQuery(['buyerInventory'],getBuyerInventory );
	if(isLoading) return <p>Loading..</p>;
	
	return (
		<Page title='Admin: Dansken inventory'>
			<Container maxWidth={'lg'} sx={{ mt: 2 }}>
				<Typography variant='h4'>Dansken inventory</Typography>
				<Typography>Sist oppdatert:{fDateTime(data.updated)}</Typography>
				<Box>
					{data.skins.map((item) => ((
						<Card key={item.item_id}>
							<Typography>
								{item.name}
							</Typography>
						</Card>
					))
					)}
				</Box>
			</Container>
		</Page>
	);
};

export default BuyerInv;