import *as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, TextField, Container, Typography } from '@mui/material';
import { postSendMail } from '../../../api/queries';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	padding: theme.spacing(2, 0),
	[theme.breakpoints.up('md')]: {
		padding: theme.spacing(4, 0)
	}
}));

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
	fullName: Yup.string().required('Fullt navn is required'),
	email: Yup.string().required('Email is required').email('That is not an email'),
	subject: Yup.string().required('Subject is required'),
	message: Yup.string().required('Message is required')
});

export default function ContactForm() {
	const { enqueueSnackbar } = useSnackbar();
	const {
		watch,
		reset,
		control,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(FormSchema),
		defaultValues: {
			fullName: '',
			subject: '',
			email: '',
			message: ''
		}
	});
	const values = watch();
	const onSubmit = async () => {
		await postSendMail(values);
		enqueueSnackbar('Mail sendt');
		reset();
	};

	return (
		<RootStyle>
			<Container>
				<Grid container spacing={3} justifyContent="center">
					<Grid item xs={12} md={8}>
						<Stack spacing={2} sx={{ mb: 5, textAlign: 'center' }}>
							<Typography variant="h3">Send oss en melding</Typography>
							<Typography sx={{ color: 'text.secondary' }}>
                Vi svarer som oftest innen 2 dager.
							</Typography>
						</Stack>

						<form onSubmit={handleSubmit(onSubmit)}>
							<Stack spacing={2.5} alignItems="flex-start">
								<Controller
									name="fullName"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											fullWidth
											label="Fullt navn"
											error={Boolean(error)}
											helperText={error?.message}
										/>
									)}
								/>

								<Controller
									name="email"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											fullWidth
											label="Email"
											error={Boolean(error)}
											helperText={error?.message}
										/>
									)}
								/>

								<Controller
									name="subject"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											fullWidth
											label="Emne"
											error={Boolean(error)}
											helperText={error?.message}
										/>
									)}
								/>

								<Controller
									name="message"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											fullWidth
											multiline
											rows={4}
											label="Melding"
											error={Boolean(error)}
											helperText={error?.message}
											sx={{ pb: 2.5 }}
										/>
									)}
								/>

								<Stack alignItems="center" width={1}>
									<LoadingButton
										size="large"
										type="submit"
										variant="contained"
										loading={isSubmitting}
									>
                    Send Message
									</LoadingButton>
								</Stack>
							</Stack>
						</form>
					</Grid>
				</Grid>
			</Container>
		</RootStyle>
	);
}