import { initializeApp } from 'firebase/app';

import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyB1Q6kk7q1YexZQDPdbVkrXu8y_lOsW3G0',
	authDomain: 'norskins-e14d1.firebaseapp.com',
	projectId: 'norskins-e14d1',
	storageBucket: 'norskins-e14d1.appspot.com',
	messagingSenderId: '1082846103333',
	appId: '1:1082846103333:web:93d25a2cf9e779a297fccc',
	measurementId: 'G-4KNEKK4Y6W'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);