import { Box, Typography } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { db } from '../firebaseconfig';
const RoomBox = (props) => {
	const status = () => {
		if(typeof props.adRead === 'undefined') {
			return true;
		}

		return props.adRead;
	};
	const hideRoom = async () => {
		const roomRef = doc(db, 'users', props.id);
		await updateDoc(roomRef, { hidden: true });
	};

	return (
		<>
			<Box
				onClick={props.onPress}
				sx={{
					backgroundColor:
            props.activeChatId === props.id
            	? 'primary.dark'
            	: !status()
            		? 'primary.light'
            		: 'background.neutral',
					borderRadius: '7px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					pr: 2,
					pl: 0.2,
					mt: 2,
					':hover': { cursor: 'pointer' },
					height: '40px'
				}}
			>
				<Typography>{props?.name ? props.name : props.id}</Typography>
				<Typography onClick={hideRoom} sx={{ zIndex: 2 }}>
          X
				</Typography>
			</Box>
		</>
	);
};

export default RoomBox;