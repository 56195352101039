import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
export default function AuthGuard({ children }) {
	const { isInitialized, isAuthenticated } = useContext(AuthContext);
	const { pathname } = useLocation();
	const [requestedLocation, setRequestedLocation] = useState(null);
	if(!isInitialized) {
		return <LoadingScreen />;
	}
	if(!isAuthenticated && !(pathname === '/sell' || pathname === '/')) {
		return <Navigate to="/" />;
	}
	if(requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}