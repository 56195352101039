import { format, formatDistanceToNow } from 'date-fns';
export function fDate(date) {
	return format(new Date(date), 'dd MMMM yyyy ');
}

export function fDateTime(date) {
	return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimeStamp(time) {
	return format(new Date(time), 'HH:mm');
}
export function fdateSteam(date) {
	return format(new Date(date), 'd MMM');
}
export function fToNow(date) {
	return date
		? formatDistanceToNow(new Date(date), {
			addSuffix: true,
		})
		: '';
}