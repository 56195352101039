import React from 'react';
import {
	AppBar,
	Box,
	Button,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from '@mui/material';
import Steamicon from '../../assets/steamicon';
import { Link } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import useResponsive from '../../hooks/useResponsive';
function Header({ isAuthenticated, user, isAdmin }) {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const isDesktop = useResponsive('up', 'md');

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const location = useLocation();

	let paths = [
		{ label: 'HJEM', path: '/' },
		{ label: 'SELG', path: '/sell', active: false },
		{ label: 'GIVEAWAY', path: '/giveaway', active: false },
	];
	if(isAuthenticated && (user.role==='external')){
		paths = [
			{ label: 'HJEM', path: '/' },
			{ label: 'SELG', path: '/sell', active: false },
			{ label: 'GIVEAWAY', path: '/giveaway', active: false },
			{ label: 'DATABASE', path: '/database', active: false },
		];
	}
	if(isAuthenticated && isAdmin) {
		paths = [
			{ label: 'HJEM', path: '/' },
			{ label: 'SELG', path: '/sell', active: false },
			{ label: 'GIVEAWAY', path: '/giveaway', active: false },
			{ label: 'ADMIN', path: '/admin', active: false },
			{ label: 'DATABASE', path: '/database', active: false },
		];
	}
	
	return (
		<AppBar variant='static'>
			<Toolbar>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<Box sx={{ width: { md: '25%', xs: '10%' } }} component={Link} to='/'>
						<Box
							sx={{ height: { md: 60, xs: 40 } }}
							component='img'
							alt='Selg dine skins til Norskins for rask betaling'
							src={isDesktop ? '/logo.png' : '/phonepic.png'}
						></Box>
					</Box>

					<Box
						sx={{
							display: { xs: 'none', md: 'flex' },
							gap: 5,
							width: '50%',
							justifyContent: 'center',
						}}
					>
						{paths.map((currentPath) => {
							return (
								<Typography
									key={currentPath.path}
									component={Link}
									to={currentPath.path}
									variant='h5'
									sx={{
										cursor: 'pointer',
										textDecoration:
                      location.pathname === currentPath.path
                      	? 'underline'
                      	: 'none',
										textDecorationColor: '#FF4B4B',
										textDecorationThickness: '2px',
										textUnderlineOffset: '26px',
										color: 'white',
										'&:hover': {
											color: 'primary.main',
										},
									}}
								>
									{currentPath.label}
								</Typography>
							);
						})}
					</Box>
					{!isAuthenticated ? (
						<Box
							sx={{
								width: { md: '25%', xs: '50%' },
								display: 'flex',
								justifyContent: { md: 'flex-end', xs: 'center' },
							}}
						>
							<Button
								variant='contained'
								component='a'
								href={
									process.env.NODE_ENV !== 'production'
										? 'http://localhost:3005/api/v1/auth/steam'
										: '/api/v1/auth/steam'
								}
							>
								<Steamicon />
								{isDesktop ? 'LOGG INN MED STEAM' : 'LOGG INN'}
							</Button>
						</Box>
					) : (
						<Box
							sx={{
								width: '20%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}
						>
							<AccountPopover user={user} isAuthenticated={isAuthenticated} />
						</Box>
					)}
					<Box sx={{ display: { xs: 'flex', md: 'none' }, width: '10%' }}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{paths.map((page) => (
								<MenuItem key={page.path} onClick={handleCloseNavMenu}>
									<Typography
										textAlign='center'
										component={Link}
										to={page.path}
										sx={{
											textDecoration:
                        location.pathname === page.path ? 'underline' : 'none',
											textDecorationColor: '#FF4B4B',
											textDecorationThickness: '2px',
											color: '#fff',
										}}
									>
										{page.label}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export default Header;