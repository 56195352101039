import React from 'react';
import { styled } from '@mui/material/styles';
import Page from '../../../components/Page';
import ContactHero from './ContactHero';
import ContactForm from './CareerContactForm';
export const HEADER_MOBILE_HEIGHT = 64;
export const HEADER_DESKTOP_HEIGHT = 40;
const RootStyle = styled('div')(({ theme }) => ({
	paddingTop: HEADER_MOBILE_HEIGHT,
	[theme.breakpoints.up('md')]: {
		paddingTop: HEADER_DESKTOP_HEIGHT
	}
}));
const Contact = () => {
	return (
		<Page title="Kontakt Oss">
			<RootStyle>
				<ContactHero />
				<ContactForm />
			</RootStyle>
		</Page>
	);
};

export default Contact;