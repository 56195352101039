import {  Container, Stack, TextField } from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { getLastDeals } from '../../../../api/queries';
import ConvertToCsv from '../../Invoices/ConvertToCsv';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fDate } from '../../../../utils/formatTime';

const LastDeals = () => {
	const [filters,setFilters] = useState({
		endDate: fDate(new Date()),
		startDate: '2023-01-01'
	});

	const { data, isLoading,error } = useQuery(['csvDownloadData', filters], () => getLastDeals(filters.startDate, filters.endDate));

	if(isLoading) {
		return (
			<Container>
				<BeatLoader color='primary.main' />
			</Container>
		);
	}
	
	return <>
		<Stack sx={{width:'20%',mt:2,justifyContent:'center'}} direction="column" gap={4}>
			<DatePicker
				label="Start Dato"
				value={filters.startDate}
				onChange={(newValue) => setFilters({ ...filters, startDate: fDate(newValue) })}
				renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
			/>
			<DatePicker
				label="Slutt Dato"
				value={filters.endDate}
				onChange={(newValue) => setFilters({ ...filters, endDate: fDate(newValue) })}
				renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
			/>
			
			{data.orders &&
			<ConvertToCsv offers={data.orders}></ConvertToCsv>
			}
		</Stack>
	</>;
};

export default LastDeals;