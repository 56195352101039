import {
	Box,
	Card,
	Container,
	Table,
	TableBody,
	TableContainer,
	TablePagination
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getAllInvs } from '../../../api/queries';
import Page from '../../../components/Page';
import TableHeadCustom from '../../../components/table/TableHeadCustom';
import useTable from '../../../hooks/useTable';
import UsersInvTableRow from './UsersInvTableRow';
const TABLE_HEAD = [
	{ id: 'id', label: 'Steam ID', align: 'left' },
	{ id: 'name', label: 'Name', align: 'left' },
	{ id: 'userlink', label: 'Inv Link', align: 'left' },
	{ id: 'inventory_value', label: 'Inv Price(Buff 100%)', align: 'center' },
	{ id: 'last_updated', label: 'Last Login', align: 'center' }
];
const UsersInvsValues = () => {
	const {
		page,
		order,
		orderBy,
		rowsPerPage,
		onSort,
		onChangePage,
		onChangeRowsPerPage
	} = useTable({ defaultOrderBy: 'last_updated', defaultOrder: 'desc' });
	const { data, isLoading } = useQuery(['userInvs',{ page, rowsPerPage, orderBy, order }], ()=> getAllInvs({ page, rowsPerPage, orderBy, order, }),{
		keepPreviousData: true // This enables smooth pagination transition
	});
	if(isLoading) {
		return <p>Henter bruker data!</p>;
	}
	
	return (
		<Page title="Admin: UserInfo">
			<Container maxWidth="lg" sx={{ mt: 2 }}>
				<Card sx={{ pt: 2, mt: 2 }}>
					<TableContainer sx={{ minWidth: 800, position: 'relative' }}>
						<Table size="medium">
							<TableHeadCustom
								order={order}
								orderBy={orderBy}
								headLabel={TABLE_HEAD}
								rowCount={data.total}
								onSort={onSort}
								woCheckBox={true}
							/>
							<TableBody>
								{data.data
									.map((row) => {
										return <UsersInvTableRow key={row.id} row={row} />;
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<Box sx={{ position: 'relative' }}>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={data.total}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={onChangePage}
							onRowsPerPageChange={onChangeRowsPerPage}
						/>
					</Box>
				</Card>
			</Container>
		</Page>
	);
};

export default UsersInvsValues;