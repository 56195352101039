import styled from '@emotion/styled';
import React from 'react';
import ChatPublic from './ChatPublic';

const Chat = () => {
	const MainStyle = styled('div')(() => ({
		position: 'fixed',
		zIndex: 99999,
		right: 0,
		bottom: 50
	}));

	return (
		<MainStyle>
			<ChatPublic />
		</MainStyle>
	);
};

export default Chat;