// @mui
import { Accordion, Typography, AccordionSummary, AccordionDetails, List, ListItem, ListSubheader } from '@mui/material';
import { Link } from 'react-router-dom';
// _mock_
// components
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------
const faqs = [{
	id: 1,
	heading: 'Hva skjer etter at jeg sender dere et byttetilbud?',
	detail: <> <Typography>Vi blir varslet om salget, og vil så fort som mulig gå inn og dobbeltsjekke at prisen er korrekt. Om alt ser greit ut, noe det gjør 99% av tiden, vil vi godta byttetilbudet og overføre pengene for salget til kontonummeret du oppga.
		<br /> <br />I svært sjeldne tilfeller vil vi avslå byttetilbudet.Dette kan skje om f.eks.l et skin er priset til 3000kr, når faktisk verdi er 1000kr(dvs.en «bug» eller liknende).
		<br /> <br />Du vil uansett motta en SMS hvor vi informerer deg om status på handelen.</Typography></>
},
{
	id: 2,
	heading: 'Når får jeg pengene mine?',
	detail: <> <Typography>Vi benytter straksbetaling hvor penger overføres til din konto umiddelbart, akkurat som ved en Vipps. Dette er ulikt en normal bankoverføring som ofte tar opp til 1 virkedag. Det spiller ingen rolle hvilken bank du har, og alt vi trenger fra deg er ditt kontonummer.
		<br /> <br />Ved nedetid hos bank kan overføringen ta opp til 1 virkedag. Tar det noen gang lenger tid enn dette, så anbefaler vi deg å ta kontakt.
	</Typography></>
},
{
	id: 3, heading: 'Hjelp! Jeg har ikke mottatt penger?', detail: <><Typography>Kontakt oss via <Link to="/kontakt" style={{ color: '#1155cc' }}>https://www.norskins.no/kontakt</Link> eller send en sms til 94166651, så undersøker vi hva som har skjedd og svarer deg så fort som mulig.</Typography></>
},
{
	id: 4, heading: 'Hvordan priser dere skins?', detail: <><Typography>Vi benytter BUFF for prising av skins, dvs. at prisene er basert på den kinesiske markedsplassen <Typography component={'a'} sx={{ display: 'inline', color: '#1155cc' }} href="https://buff.163.com/" target="_blank">https://buff.163.com/</Typography>. Vi prøver å holde oss konkurransedyktige, og vil stort sett tilby ca. 88% av denne prisen. Dette er markedet med størst volum og de mest stabile prisene for csgo skins.</Typography></>
},
{
	id: 5,
	heading: 'Skinnet mitt er underpriset',
	detail: <> <Typography>I utgangspunktet betaler vi ikke ekstra for float/stickers/patterns, men om du føler at skinnet ditt er urettferdig priset, ta kontakt, så kan vi komme med et tilpasset pristilbud.</Typography>
		<List sx={{ listStyleType: 'disc', pl: 2 }}>
			<ListSubheader sx={{
				fontWeight: 700, lineHeight: '24px', fontSize: '16px'
			}}
			>
        		Eksempler på hva vi kan gi ekstra for:
			</ListSubheader>
			<ListItem sx={{ display: 'list-item' }}>Karambit | Fade FN 98%</ListItem>
			<ListItem sx={{ display: 'list-item' }}>Driver Gloves | King Snake 0.16 float.</ListItem>
		</List>
		<List sx={{ listStyleType: 'disc', pl: 2 }}>
			<ListSubheader sx={{
				fontWeight: 700, lineHeight: '24px', fontSize: '16px'
			}}
			>
        Eksempler på hva vi ikke gir ekstra for:
			</ListSubheader>
			<ListItem sx={{ display: 'list-item' }}>Talon Knife | Marble Fade 0.007 float.</ListItem>
			<ListItem sx={{ display: 'list-item' }}>Specialist Gloves | Tiger Strike 0.28 float.</ListItem>
			<ListItem sx={{ display: 'list-item' }}>Slaughter patterns.</ListItem>
		</List>
	</>
},
{
	id: 6,
	heading: 'Hva er et kontonummer og hva skjer om jeg skriver inn feil kontonummer?',
	detail: <>
		<Typography>Et kontonummer er et unikt 11-siffer langt tall, ofte oppgitt på baksiden av bankkort. Du kan også finne det i nettbanken din. Hvert kontonummer er knyttet opp mot en bankkonto.</Typography>
		<Typography><Typography component={'span'} sx={{ fontWeight: 700 }}>Eksempel:</Typography> 1206 16 12345.</Typography>
		<br></br>
		<Typography>Dette må ikke forveksles med det 16-siffer lange kortnummeret på forsiden av alle bankkort.</Typography>
		<br></br>
		<Typography>Alle kontonumre har minst 2 ulike sifre, så om du skriver inn 1 siffer feil, vil vi få opp en feilmelding ved utsending av penger og ta kontakt med deg. Det er ditt ansvar å påse at kontonummeret du oppgir er korrekt. Vi er ikke erstatningsansvarlige om du skriver inn 2 eller flere sifre feil.</Typography>
	</>
},
{
	id: 7, heading: 'Hvorfor ber dere om navnet mitt og adressen min?', detail: <> <Typography>Dette er for å kunne føre korrekt regnskap i forhold til bokføringsloven. Du vil aldri få tilsendt noe i posten. Kopi av kjøpsfaktura kan gis ut ved forespørsel.</Typography></>
}
];
export default function FaqsList() {
	return (
		<>
			{faqs.map((accordion) => (
				<Accordion key={accordion.id}>
					<AccordionSummary
						expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}
					>
						<Typography variant="subtitle1">{accordion.heading}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{accordion.detail}
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);
}