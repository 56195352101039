// @mui
import { Card, Skeleton, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonProductItem() {
	return (
		<Card sx={{height: '200px',width: '144px'}}>
			<Skeleton variant="rectangular" sx={{ paddingTop: '100%' }} />
			<Stack spacing={2} sx={{ p: 3 }}>
				<Skeleton variant="text" sx={{ width: 0.5 }} />
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Stack direction="row">
						<Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
						<Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
						<Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
					</Stack>
					<Skeleton variant="text" sx={{ width: 40 }} />
				</Stack>
			</Stack>
		</Card>
	);
}