import { Box, Card, Typography } from '@mui/material';
import React, { useContext } from 'react';
import Image from '../../components/Image';
import CircleIcon from '@mui/icons-material/Circle';
import { WarningIcon } from '../../theme/overrides/CustomIcons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import conditionShortner from '../../utils/conditionShortner';
import { AuthContext } from '../../contexts/AuthContext';

const SkinCard = ({
	product,
	cartItems,
	handleClick,
	balance,
	getTotalPrice,
}) => {
	const {isAdmin} = useContext(AuthContext);
	const isAdded = (assetId) => {
		return cartItems.filter((e) => e.assetid === assetId).length > 0
			? true
			: false;
	};
	const {
		imagelink,
		skinname,
		tradeability,
		rarity,
		assetid,
		weapon,
		condition,
		stickers,
		floatvalue,
		liquidity,
		bought_price
	} = product;
	const tradable = tradeability;
	const imageLink=imagelink;
	const skinName = skinname;
	const reasonNotAccepted = () => {
		if(liquidity === false) {
			return 'Ustabil Pris';
		} else if(!tradable) {
			return 'Untradeable';
		} else{
			return 'Unknown';
		}
	};
	const addeableChecker = (bought_price, tradable) => {
		if(isAdmin){
			return true;
		}
		if(!liquidity){
			return false;
		}
		if(balance !== false) {
			if(
				balance > bought_price &&
				tradable &&
				bought_price > 200 &&
				bought_price < balance - getTotalPrice()
			) {
				return true;
			} else if(isAdded(assetid)) {
				return true;
			} else{
				return false;
			}
		} else{
			if(tradable && bought_price > 200) {
				return true;
			} else{
				return false;
			}
		}
	};
	const CreatStickers = ({ stickers }) => {
		return (
			<Box sx={{ display: 'flex' }}>
				{stickers.map((sticker, index) => (
					<img
						style={{ width: '32px', height: '24px' }}
						alt='sticker'
						src={sticker}
						key={index}
					/>
				))}
			</Box>
		);
	};
	
	return (
		<Card
			sx={{
				borderRadius: '4px',
				height: '200px',
				width: '144px',
				'&:hover': {
					cursor: addeableChecker(bought_price/100, tradable)
						? 'pointer'
						: 'auto',
					backgroundColor: addeableChecker(bought_price/100, tradable)
						? '#28292D'
						: '',
				},
			}}
			onClick={() =>
				addeableChecker(bought_price/100, tradable)
					? handleClick(product)
					: undefined
			}
		>
			{isAdded(assetid) && (
				<Box
					sx={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						zIndex: 5,
						border: '2px solid',
						borderColor: 'primary.main',
					}}
				>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<CheckCircleIcon color='primary' />
					</Box>
				</Box>
			)}
			<Box sx={{ pl: 2, pb: 2, pt: 1.4 }}>
				{bought_price/100 > 200 && liquidity ? (
					<Typography
						variant='subtitle2'
						sx={{
							lineHeight: '1.153125rem',
							fontSize: '1rem',
							fontWeight: 600,
							fontFamily: 'Clash Grotesk Semibold',
						}}
					>
						{balance === false && 'Kr'} {(bought_price/100).toFixed(0)}.
						<Typography
							variant='body2'
							display={'inline'}
							sx={{
								lineHeight: '0.9rem',
								fontWeight: 500,
								fontFamily: 'Clash Grotesk Medium',
							}}
						>
							{(bought_price/100).toFixed(2).split('.')[1]}
						</Typography>
					</Typography>
				) : (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
						<WarningIcon
							sx={{ color: '#52575C', fontSize: '17px' }}
						></WarningIcon>
						<Typography
							variant='body2'
							sx={{
								fontFamily: 'Clash Grotesk Medium',
								lineHeight: '1.0625rem',
								fontWeight: 500,
							}}
						>
							{reasonNotAccepted()}
						</Typography>
					</Box>
				)}
			</Box>
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '121px',
					height: '81px',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				<Image
					alt={skinName}
					src={imageLink?.includes('http') ? imageLink : `https://community.cloudflare.steamstatic.com/economy/image/${imageLink}`}
					ratio='4/3'
					sx={{
						opacity: addeableChecker(bought_price/100, tradable) ? 1 : 0.2,
					}}
				/>
			</Box>
			<Box
				sx={{
					pt: 3,
					ml: 1.2,
					width: '65%',
					height: '30%',
					display: 'flex',
					position: 'relative',
					alignItems: 'center',
				}}
			>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<CircleIcon
							sx={{
								fontSize: '8px',
								mr: 0.5,
								color: addeableChecker(bought_price/100, tradable)
									? rarityColor(rarity)
									: '#52575C',
							}}
						/>
						<Typography
							noWrap
							sx={{
								fontFamily: 'Clash Grotesk Medium',
								fontWeight: 500,
								width: '100%',
							}}
							variant='smallBody'
						>
							{weapon[0] === '★' ? weapon.slice(1) : weapon}
						</Typography>
					</Box>
					<Typography
						variant='body2'
						noWrap
						sx={{
							fontFamily: 'Clash Grotesk Semibold',
							fontWeight: 600,
							lineHeight: '1.0625rem',
							color: addeableChecker(bought_price/100, tradable)
								? rarityColor(rarity)
								: '#52575C',
							width: '100%',
						}}
					>
						{skinName}
					</Typography>
					{!tradable && (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
							<WarningIcon
								sx={{ color: '#52575C', fontSize: '12px' }}
							></WarningIcon>
							<Typography
								variant='body2'
								sx={{
									fontFamily: 'Clash Grotesk Medium',
									lineHeight: '1.0625rem',
									fontWeight: 500,
									fontSize: '10px',
								}}
							>
								{reasonNotAccepted()}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: 18,
					right: 4,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography variant='smallBody' sx={{ opacity: 0.7 }}>
					{conditionShortner(condition)}
				</Typography>
				<Typography
					variant='smallBody'
					sx={{ fontFamily: 'Clash Grotesk Medium' }}
				>
					{floatvalue?.toFixed(3)}
				</Typography>
			</Box>
			{stickers && (
				<Box sx={{ position: 'absolute', top: '60%', left: 0, width: '100%' }}>
					<CreatStickers stickers={stickers} />
				</Box>
			)}
		</Card>
	);
};

export default SkinCard;

const rarityColor = (rarity) => {
	if(rarity === 'eb4b4b') {
		return '#FF4B32';
	} else if(rarity === 'd32ce6') {
		return '#FF32AD';
	} else if(rarity === '8847ff') {
		return '#8C32FF';
	} else if(rarity === '4b69ff') {
		return '#3278FF';
	} else{
		return '#' + rarity;
	}
};