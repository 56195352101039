import {
	Avatar,
	MenuItem,
	Divider,
	Stack,
	Box,
	Typography,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../animate';
import useAuth from '../../hooks/useAuth';
const MENU_OPTIONS = [
	{
		label: 'Home',
		linkTo: '/',
	},
	{
		label: 'Profil',
		linkTo: '/profil',
	},
];
function AccountPopover({ user }) {
	const { logout } = useAuth();
	const [open, setOpen] = useState(null);
	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};
	const handleLogout = async () => {
		try{
			await logout();
		} catch(err) {
			console.log(err);
		}
	};
	
	return (
		<>
			<IconButtonAnimate
				onClick={handleOpen}
				sx={{
					p: 0,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: '\'\'',
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
			>
				<Avatar src={user.photos[1].value}></Avatar>
			</IconButtonAnimate>
			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
					p: 0,
					mt: 1.5,
					ml: 0.75,
					'& .MuiMenuItem-root': {
						typography: 'body2',
						borderRadius: 0.75,
					},
				}}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant='subtitle2' noWrap>
						{user?.displayName}
					</Typography>
					<Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
						{user?.email}
					</Typography>
				</Box>
				<Divider sx={{ borderStyle: 'dashed' }} />
				<Stack sx={{ p: 1 }}>
					{MENU_OPTIONS.map((option) => (
						<MenuItem
							key={option.label}
							to={option.linkTo}
							component={RouterLink}
							onClick={handleClose}
						>
							{option.label}
						</MenuItem>
					))}
					<MenuItem
						to={'/referrals/dashboard'}
						component={RouterLink}
						onClick={handleClose}
					>
            Referrals
					</MenuItem>
				</Stack>
				<Divider sx={{ borderStyle: 'dashed' }} />
				<MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          LOGOUT
				</MenuItem>
			</MenuPopover>
		</>
	);
}

export default AccountPopover;