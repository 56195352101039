import {
	Box,
	Button,
	Card,
	Container,
	MenuItem,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCashoutId, putUpdateCashout } from '../../../../api/queries';
import Label from '../../../../components/Label';
import { fDate } from '../../../../utils/formatTime';
const RowResultStyle = styled(TableRow)(({ theme }) => ({
	'& td': {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	}
}));
const CashoutEditRow = () => {
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [currentStatus, setCurrentStatus] = useState(1);
	const { data: details, isLoading } = useQuery(
		['cashoutDetails', id],
		() => getCashoutId(id),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setCurrentStatus(data.status);
			}
		}
	);
	const updateStatusMutate = useMutation(putUpdateCashout, {
		onSuccess: () => {
			enqueueSnackbar('Status Oppdatert');
		}
	});
	const handleSelectChange = (event) => {
		const inpData = { id, newStatus: event.target.value };
		updateStatusMutate.mutate(inpData);
		setCurrentStatus(event.target.value);
	};
	if(isLoading) {
		return <p>Loading..</p>;
	}
	const { created_at, skins, balance, steam_id, tradelink, name } = details;
	
	return (
		<Container maxWidth="lg">
			<Typography variant="h4">Cashout Id</Typography>
			<Card sx={{ px: 5, pt: 2 }}>
				<Stack gap={4} sx={{ fontFamily: '\'Public Sans\',sans-serif;' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<Typography
								paragraph
								variant="overline"
								sx={{
									color: 'text.disabled',
									fontFamily: '\'Public Sans\',sans-serif;'
								}}
							>
                				Offer from
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontFamily: '\'Public Sans\',sans-serif;' }}
							>
								{steam_id}
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontFamily: '\'Public Sans\',sans-serif;' }}
							>
								{name}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Label
								variant={'filled'}
								color={
									(convertStatus(currentStatus) === 'Godtatt' && 'success') ||
									(convertStatus(currentStatus) === 'Open' && 'info') ||
									(convertStatus(currentStatus) === 'Declined' && 'error') ||
									'default'
								}
								sx={{
									textTransform: 'uppercase',
									mb: 1,
									fontFamily: '\'Public Sans\',sans-serif;'
								}}
							>
								{convertStatus(currentStatus)}
							</Label>
							{convertStatus(currentStatus) === 'Open' && (
								<Button
									variant="contained"
									sx={{ fontFamily: 'sans-serif' }}
									component={'a'}
									href={tradelink}
									target="_blank"
								>
                  					SEND OFFER
								</Button>
							)}
						</Box>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<Typography
								paragraph
								variant="overline"
								sx={{
									color: 'text.disabled',
									fontFamily: '\'Public Sans\',sans-serif;'
								}}
							>
                				Date Created
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontFamily: '\'Public Sans\',sans-serif;' }}
							>
								
								{fDate(created_at)}
							</Typography>
						</Box>
						<Box>
							<Select
								label="Age"
								onChange={handleSelectChange}
								value={currentStatus}
								disabled={currentStatus===0}
							>
								<MenuItem value={1}>Åpen</MenuItem>
								<MenuItem value={2}>Godtatt</MenuItem>
								<MenuItem value={0}>Avslått</MenuItem>
							</Select>
						</Box>
					</Box>
				</Stack>
				<TableContainer sx={{ minWidth: 960 }}>
					<Table>
						<TableHead
							sx={{
								borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
								'& th': { backgroundColor: 'transparent' }
							}}
						>
							<TableRow>
								<TableCell width={40}>#</TableCell>
								<TableCell align="left">Name</TableCell>
								<TableCell align="right">Full price</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{skins.map((row, index) => {
								const { fullname, assetid, stickers, buff_price, float } = row;

								return (
									<TableRow
										key={assetid}
										sx={{
											borderBottom: (theme) =>
												`solid 1px ${theme.palette.divider}`
										}}
									>
										<TableCell>{index + 1}</TableCell>
										<TableCell align="left">
											<Box sx={{ maxWidth: 560 }}>
												<Typography
													variant="subtitle2"
													sx={{ fontFamily: '\'Public Sans\',sans-serif;' }}
												>
													{fullname + '[' + float?.toFixed(4) + ']'}
												</Typography>
												<Stack direction={'row'}>
													{stickers &&
														stickers.map((sticker, index) => (<Box key={index} component="img" src={sticker} sx={{ height: '25px' }} />
														))}
												</Stack>
											</Box>
										</TableCell>
										<TableCell align="right">
											<Typography sx={{ fontFamily: 'Clash Grotesk Medium' }}>
                        						Kr {(buff_price/100).toFixed(0)}
											</Typography>
										</TableCell>
									</TableRow>
								);
							})}
							<RowResultStyle>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Box sx={{ mt: 2 }} />
									<Typography>OfferTotal:</Typography>
								</TableCell>
								<TableCell align="right" width={120}>
									<Box sx={{ mt: 2 }} />
									<Typography variant="subtitle1">
                						Kr {skins.reduce((prev, next) => prev + parseInt(next.buff_price/100), 0)}
									</Typography>
								</TableCell>
							</RowResultStyle>
							<RowResultStyle>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Typography variant="body1">Bruker Balance</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="subtitle1">{balance}</Typography>
								</TableCell>
							</RowResultStyle>
						</TableBody>
					</Table>
				</TableContainer>
			</Card>
		</Container>
	);
};

export default CashoutEditRow;
const convertStatus = (status) => {
	if(status === 1) {
		return 'Open';
	} else if(status === 2) {
		return 'Godtatt';
	} else if(status === 0) {
		return 'Declined';
	} else if(status === 'open') {
		return 1;
	} else if(status === 'godtatt') {
		return 2;
	} else if(status === 'declined') {
		return 0;
	}
};