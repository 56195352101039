import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../../contexts/AuthContext';
import { Stack } from '@mui/material';
import Footer from './Footer';
import Chat from '../../Pages/Chat';
const HEADER = {
	MOBILE_HEIGHT: 34,
	MAIN_DESKTOP_HEIGHT: 40,
	DASHBOARD_DESKTOP_HEIGHT: 40,
	DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 40
};

const MainStyle = styled('main')(({ theme }) => ({
	flexGrow: 1,
	paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
	[theme.breakpoints.up('md')]: {
		paddingLeft: 16,
		paddingRight: 16
	}
}));
const MainLayout = () => {
	const { isAuthenticated, user, isAdmin } = useContext(AuthContext);

	return (
		<>
			<Stack sx={{ minHeight: 1 }}>
				<Header user={user} isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
				<MainStyle>
					<Outlet />
				</MainStyle>
				<Footer />
			</Stack>
			<Chat />
		</>);
};
export default MainLayout;