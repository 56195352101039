import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { Box } from '@mui/material';

const Page = forwardRef(({ children, title = '',description='', meta, ...other }, ref) => (
	<>
		<Helmet>
			<title>{`${title} | Norskins`}</title>
			{description && <meta name="description" content={description} />}
			{meta}
		</Helmet>
		<h1 style={{ position: 'absolute', width: '1px', height: '1px', padding: '0', margin: '-1px', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', border: '0' }}>{`${title}`}</h1>
		<Box ref={ref} {...other}>
			{children}
		</Box>
	</>
));

Page.displayName = 'Page';

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	meta: PropTypes.node,
};

export default Page;