import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/LoadingScreen';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../guards/AuthGuard';
import MainLayout from '../layouts/main';
import AdminGuard from '../guards/AdminGuard';
import UsersInvsValues from '../Pages/Admin/userInvsValuesFold/UsersInvsValues';
import Contact from '../Pages/Blogs/Contact';
import FrequentlyAsked from '../Pages/Blogs/FAQ';
import Profits from '../Pages/Admin/Profits';
import BotProtectionPage from '../Pages/BotProtectionPage';
import BotLayout from '../layouts/BotLayout';
import ReferralCashout from '../Pages/Referrals/Dashboard/ReferralCashout';
import AdminCashouts from '../Pages/Admin/Cashouts';
import CashoutEditRow from '../Pages/Admin/Cashouts/edit';
import ChatRoom from '../Pages/Chat/ChatRoom';
import ExternalMain from '../Pages/External';
import ExternalGuard from '../guards/ExternalGuard';
import AdminStatuses from '../components/AdminStatuses';
import Downloads from '../Pages/Admin/Downloads';
import BanningItems from '../Pages/Admin/BanningItems';
import BuyerInv from '../Pages/Admin/BuyerInv';
/* eslint-disable react/display-name */
const Loadable = (Component) => (props) => {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);
};
export default function Router() {
	return useRoutes([
		{
			path: '/',
			element: (
				<AuthGuard>
					<MainLayout />
				</AuthGuard>
			),
			children: [
				{ element: <Home />, index: true },
				{ path: 'sell', element: <SecondPage /> },
				{path:'trades',element:<Trades/>},
				{
					path: 'referrals',
					children: [
						{
							index: true,
							element: <Referral />,
							path: 'dashboard',
						},
						{
							path: 'cashout',
							element: <ReferralCashout />,
						},
					],
				},
				{
					path: 'profil',
					children: [
						{ index: true, element: <Profile /> },
						{ path: 'offer/:id', element: <InvoiceView /> },
					],
				},
			],
		},
		{
			path: 'admin',
			element: (
				<AdminGuard>
					<MainLayout></MainLayout>
				</AdminGuard>
			),
			children: [
				{ index: true, element: <Admin /> },
				{path:'buyerInv',element:<BuyerInv/>},
				{ path: 'offer/:id', element: <InvoiceView /> },
				{ path: 'offer/:id/edit', element: <InvoiceEdit /> },
				{ path: 'invvalues', element: <UsersInvsValues /> },
				{ path: 'profits', element: <Profits /> },
				{path:'blacklists',element:<BanningItems/>},
				{
					path: 'settings',
					element:<AdminStatuses />
				},
				{ path: 'downloads', element: <Downloads /> },
				{ path: 'manuell', element: <ManuellMain /> },
				{
					path: 'referrals',
					children: [
						{ path: 'cashouts', element: <AdminCashouts /> },
						{ path: 'cashouts/:id', element: <CashoutEditRow /> },
					],
				},

				{ path: 'chat', element: <ChatRoom /> },
			],
		},
		{
			path: 'database',
			element: (
				<ExternalGuard>
					<MainLayout></MainLayout>
				</ExternalGuard>
			),
			children: [{ index: true, element: <ExternalMain /> }],
		},
		{
			path: '/',
			element: <MainLayout></MainLayout>,
			children: [
				{ path: 'privacy-policy', element: <PrivacyPolicy /> },
				{ path: 'tos', element: <Brukervilkår /> },
				{ path: 'kontakt', element: <Contact /> },
				{ path: 'faq', element: <FrequentlyAsked /> },
				{path:'blogs',children:[
					{path:'phishing',element:<Phishing/>}
				]},
				{ path: 'giveaway', element: <Giveaway /> },
			],
		},
		
		{
			path: '/bot',
			element: <BotLayout />,
			children: [{ index: true, element: <BotProtectionPage /> }],
		},
		{ path: '*', element: <Navigate to='/' /> },
	]);
}
const SecondPage = Loadable(lazy(() => import('../Pages/Sell')));
const Trades = Loadable(lazy(() => import('../Pages/Trades')));
const Home = Loadable(lazy(() => import('../Pages/Home')));
const Giveaway = Loadable(lazy(() => import('../Pages/Giveaway')));
const Admin = Loadable(lazy(() => import('../Pages/Admin')));
const InvoiceView = Loadable(
	lazy(() => import('../Pages/Admin/Invoices/InvoiceView'))
);
const Profile = Loadable(lazy(() => import('../Pages/Profile')));
const InvoiceEdit = Loadable(
	lazy(() => import('../Pages/Admin/Invoices/InvoiceEdit'))
);
const PrivacyPolicy = Loadable(
	lazy(() => import('../Pages/Blogs/PrivacyPolicy'))
);
const Brukervilkår = Loadable(
	lazy(() => import('../Pages/Blogs/Brukervilkår'))
);
const ManuellMain = Loadable(lazy(() => import('../Pages/Admin/manuell')));
const Referral = Loadable(lazy(() => import('../Pages/Referrals/Dashboard')));
const Phishing = Loadable(lazy(() => import('../Pages/Blogs/Phishing')));