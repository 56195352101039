import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import Page from '../../../components/Page';
import { useQuery } from '@tanstack/react-query';
import { getProfits, getVolume } from '../../../api/queries';
import Chart from 'react-apexcharts';
import merge from 'lodash/merge';
import BaseOptionChart from '../../../components/BaseOptionChart';
import ToppSelgere from './TopSelgere';
/* const days = ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"]; */
const Profits = () => {
	const { isLoading, data } = useQuery(['profits'], getProfits);
	const { isLoadingVolume, data: volumeData } = useQuery(['volume'], getVolume);
	if(isLoading || isLoadingVolume) {
		return <p>Loading...</p>;
	}
	console.log(volumeData);
	const days = data?.days;
	const options = merge(BaseOptionChart(), {
		xaxis: {
			categories: days
		}
	});
	const monthlyOptions = merge(BaseOptionChart(), {
		xaxis: {
			categories: volumeData.MonthlyVolume.map((i)=>{return i.month_number;})
		}
	});
	const weeklyOptions = merge(BaseOptionChart(), {
		xaxis: {
			categories: volumeData.WeeklyVolume.map((i)=>{return i.week_number;})
		}
	});

	const series = [
		{
			name: 'Profit',
			data: data.daysResults
		}
	];
	const weeklyVolumeSeries=[
		{name:'Weekly',data:volumeData.WeeklyVolume.map((i)=>{return i.total_volume_per_week;})},
	];
	
	const monthlyVolumeSeries=[
		{name:'Monthly',data:volumeData.MonthlyVolume.map((i)=>{return i.total_volume_per_month;})},
	];

	return (
		<Page title='Admin Profits'>
			<Container maxWidth={true} sx={{ mt: 5 }}>
				<Stack direction='row'>
					<Box sx={{display:'flex', flexDirection:'column'}}>
						<Typography>
          Total Profit Last 7 days so far:
							{data.WeekProfitSum}
          kr
						</Typography>
						<Typography>
          			Total profit siden 2023-10-29:{'  '}
							<Typography
								sx={{
									color: 'success.main',
									display: 'inline-block',
									fontWeight: 700
								}}
								inline
							>
								{data.OverallProfit} kr
							</Typography>
						</Typography>
						<Box>
							<Chart type='line' width='500' series={series} options={options} />
							<ToppSelgere title='Topp Selgere' list={data.TopSellers} />
						</Box>
					</Box>
					<Box sx={{}}>
						<Box>
							<Typography>Bought total Weekly</Typography>
							<Chart type='line' width='1000' series={weeklyVolumeSeries} options={weeklyOptions} />
						</Box>
						<Box>
							<Typography>Bought total Monthly</Typography>
							<Chart type='line' width='1000' series={monthlyVolumeSeries} options={monthlyOptions} />
						</Box>
					</Box>
				</Stack>
			</Container>
		</Page>
	);
};

export default Profits;