import MotionLazyContainer from './animate/MotionLazyContainer';
import './App.css';
import Router from './routes';
import ThemeProvider from './theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import NotistackProvider from './components/NotistackProvider';
import ChartStyle from './components/ChartStyle';
/* import MaintenancePage from './Pages/maintenance/MaintenancePage'; */
const queryClient = new QueryClient();
function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<MotionLazyContainer>
				<ThemeProvider>
					<NotistackProvider>
						{/* <MaintenancePage /> */}
						<ChartStyle />
						<Router />
					</NotistackProvider>
				</ThemeProvider>
			</MotionLazyContainer>
		</QueryClientProvider>
	);
}

export default App;