import {
	Box,
	Card,
	Checkbox,
	Container,
	Divider,
	IconButton,
	InputAdornment,
	Stack,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Tabs,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import React, { useState } from 'react';
import Label from '../../components/Label';
import TableHeadCustom from '../../components/table/TableHeadCustom';

import { useTheme } from '@mui/material/styles';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
import { fDateTime } from '../../utils/formatTime';
import InvoiceAnalytic from '../Admin/Invoices/InvoiceAnalytic';
import TableSelectedActions from '../../components/table/TableSelectedActions';
import Iconify from '../../components/Iconify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { getBuys, getBuysSummary, putUpdateBuys } from '../../api/queries';
import BeatLoader from 'react-spinners/BeatLoader';
const TABLE_HEAD = [
	{ id: 'placeholder', label: '', align: 'left' },
	{ id: 'created_date', label: 'Bought Date', align: 'left' },
	{ id: 'fullname', label: 'Skin Name', align: 'left' },
	{ id: 'formatted_price', label: 'Bought Price', align: 'center', width: 140 },
	{ id: 'percentage', label: 'Percentage', align: 'center', width: 140 },
	{ id: 'status', label: 'status', align: 'right' }
];
const DatabaseTable = () => {
	const [filterName, setFilterName] = useState('');
	const { currentTab: filterStatus, setCurrentTab } = useTabs('all');
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();
	const markAsBoughtMutate = useMutation(putUpdateBuys, {
		onSuccess: () => {
			enqueueSnackbar('Status Oppdatert');
			queryClient.invalidateQueries('buys');
		},
		onError: () => {
			enqueueSnackbar('You must be admin to do that!', {
				variant: 'error'
			});
		}
	});
	const {
		page,
		setPage,
		order,
		orderBy,
		rowsPerPage,
		//
		selected,
		setSelected,
		onSelectRow,
		onSelectAllRows,
		toggleSelectAll,
		//
		onSort,
		onChangePage,
		onChangeRowsPerPage
	} = useTable({ defaultOrderBy: 'created_date', defaultOrder: 'desc' });
	const { isLoading, data } = useQuery(
		['buys', { page, rowsPerPage, filterStatus, orderBy, order, filterName }],
		() =>
			getBuys({
				page,
				rowsPerPage,
				type: filterStatus,
				orderBy,
				order,
				filterName
			}),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
	const { isLoading: summaryLoading, data: summaryData } = useQuery(
		['buysSummary'],
		() => getBuysSummary()
	);
	const handleFilterName = (filterName) => {
		setFilterName(filterName);
		setPage(0);
	};
	const theme = useTheme();

	const handleUpdateRows = async (selected) => {
		markAsBoughtMutate.mutate(selected);
		setSelected([]);
		setPage(0);
	};

	if(isLoading || summaryLoading) {
		return <BeatLoader color='primary.main' />;
	}
	const TABS = [
		{
			value: 'all',
			label: 'Alle',
			color: 'info',
			count: summaryData.all.amount
		},
		{
			value: 'unpaid',
			label: 'Unpaid',
			color: 'error',
			count: summaryData.unpaid.amount
		},
		{
			value: 'due',
			label: 'Due',
			color: 'warning',
			count: summaryData.due.amount
		},
		{
			value: 'paid',
			label: 'Paid',
			color: 'success',
			count: summaryData.paid.amount
		}
	];
	const onFilterStatus = (event, newValue) => {
		if(summaryData[newValue].amount < rowsPerPage * page) {
			setPage(0);
		}
		setCurrentTab(newValue);
	};

	return (
		<Container maxWidth='lg'>
			<Box>
				<Card sx={{ mb: 5, mt: 2 }}>
					{summaryLoading
						? (
							<BeatLoader color='primary.main' />
						)
						: (
							<Stack
								direction='row'
								divider={
									<Divider
										orientation='vertical'
										flexItem
										sx={{ borderStyle: 'dashed' }}
									/>
								}
								sx={{ py: 2 }}
							>
								<InvoiceAnalytic
									title='Total'
									total={summaryData.all.amount}
									percent={100}
									price={summaryData.all.sum}
									icon='ic:round-receipt'
									color={theme.palette.info.main}
									curr='$'
								/>
								<InvoiceAnalytic
									title='Paid'
									total={summaryData.paid.amount}
									percent={summaryData.paid.percent}
									price={summaryData.paid.sum}
									icon='eva:clock-fill'
									color={theme.palette.success.main}
									curr='$'
								/>
								<InvoiceAnalytic
									title='Unpaid'
									total={summaryData.unpaid.amount}
									percent={summaryData.unpaid.percent}
									price={summaryData.unpaid.sum}
									icon='eva:clock-fill'
									color={theme.palette.error.main}
									curr='$'
								/>
								<InvoiceAnalytic
									title='Due'
									total={summaryData.due.amount}
									percent={summaryData.due.percent}
									price={summaryData.due.sum}
									icon='eva:clock-fill'
									color={theme.palette.warning.main}
									curr='$'
								/>
							</Stack>
						)}
				</Card>
				<Card>
					<Tabs
						value={filterStatus}
						onChange={onFilterStatus}
						sx={{ px: 2, bgcolor: 'background.neutral' }}
						allowScrollButtonsMobile
						variant='scrollable'
						scrollButtons='auto'
					>
						{TABS.map((tab) => (
							<Tab
								disableRipple
								key={tab.value}
								value={tab.value}
								icon={<Label color={tab.color}> {tab.count} </Label>}
								label={tab.label}
							/>
						))}
					</Tabs>
					<Divider />
					<Stack
						spacing={2}
						direction={{ xs: 'column', md: 'row' }}
						sx={{ py: 2.5, px: 3 }}
					>
						<TextField
							fullWidth
							value={filterName}
							onChange={(event) => handleFilterName(event.target.value)}
							placeholder='Søk etter skin navn...'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Iconify
											icon={'eva:search-fill'}
											sx={{ color: 'text.disabled', width: 20, height: 20 }}
										/>
									</InputAdornment>
								)
							}}
						/>
					</Stack>
					<TableContainer sx={{ minWidth: 800, position: 'relative' }}>
						<Table size='medium'>
							{selected.length > 0 && (
								<TableSelectedActions
									numSelected={selected.length}
									rowCount={data.bought_deals.length}
									onSelectAllRows={() => {
										const visibleRows = data.bought_deals
											.filter((row) => row.itemsold !== true)
											.map((row) => row.skin_id);
										const isSelectedAll = visibleRows.every((id) =>
											selected.includes(id)
										);
										toggleSelectAll(visibleRows, isSelectedAll);
									}}
									rowsSelected={data.bought_deals
										.filter((row) => row.itemsold !== true)
										.map((row) => row.skin_id)
										.every((item) => selected.includes(item))}
									actions={
										<Tooltip title='Kjøpt'>
											<IconButton
												color='primary'
												onClick={() => handleUpdateRows(selected)}
											>
												<Iconify icon={'material-symbols:archive'} />
											</IconButton>
										</Tooltip>
									}
								/>
							)}
							<TableHeadCustom
								order={order}
								orderBy={orderBy}
								headLabel={
									filterStatus === 'paid'
										? TABLE_HEAD
										: TABLE_HEAD.filter((head) => head.id !== 'placeholder')
								}
								rowCount={summaryData.all.amount}
								numSelected={selected.length}
								onSort={onSort}
								woCheckBox={data.amountNotSold === 0 || filterStatus === 'paid'}
								onSelectAllRows={(checked) =>
									onSelectAllRows(
										checked,
										data.bought_deals
											.filter((row) => row.itemsold !== true)
											.map((row) => row.skin_id)
									)
								}
							/>
							<TableBody>
								{data.bought_deals.map((row) => {
									return (
										<TableRow key={row.skin_id} hover>
											{data.amountNotSold !== 0 && (
												<TableCell padding='checkbox'>
													{converNumberStatus(row.status) !== 'Paid' && (
														<Checkbox
															checked={selected.includes(row.skin_id)}
															onClick={() => onSelectRow(row.skin_id)}
														/>
													)}
												</TableCell>
											)}
											<TableCell>{fDateTime(row.created_date)}</TableCell>
											<TableCell>{`${row.fullname}`}</TableCell>
											<TableCell align='center'>
												<Typography>{row.adjusted_price} $</Typography>
											</TableCell>
											<TableCell align='center'>
												<Typography>{row.percentage * 100} %</Typography>
											</TableCell>
											<TableCell align='right'>
												<Label
													variant={'filled'}
													color={
														(converNumberStatus(row.status) === 'Paid' &&
														'success') ||
														(converNumberStatus(row.status) === 'Due' &&
														'warning') ||
														(converNumberStatus(row.status) === 'Unpaid' &&
														'error') ||
														'default'
													}
													sx={{
														textTransform: 'uppercase',
														mb: 1,
														fontFamily: '\'Public Sans\',sans-serif;'
													}}
												>
													{converNumberStatus(row.status)}
												</Label>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<Box sx={{ position: 'relative' }}>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component='div'
							count={summaryData[filterStatus].amount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={onChangePage}
							onRowsPerPageChange={onChangeRowsPerPage}
						/>
					</Box>
				</Card>
			</Box>
		</Container>
	);
};
const converNumberStatus = (status) => {
	switch(status) {
	case 0:
		return 'Unpaid';
	case 1:
		return 'Due';
	case 2:
		return 'Paid';
	default:
		return 'Unpaid';
	}
};

export default DatabaseTable;