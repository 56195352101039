import { Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Page from '../../components/Page';

const BotProtectionPage = () => {
	return (
		<Page title="Bot Protection">
			<Container maxWidth="md">
				<Typography variant="h1">If you see this page you most likely a bot!</Typography>
				<Typography variant='h3'>Hvis du har blitt sendt til denne siden og er ikke en bot, <Link to={'/kontakt'}>kontakt oss her</Link></Typography>
			</Container>
		</Page>
	);
};

export default BotProtectionPage;