// ----------------------------------------------------------------------
export default function CssBaseline() {
	return {
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					margin: 0,
					padding: 0,
					boxSizing: 'border-box',
				},
				html: {
					width: '100%',
					height: '100%',
					WebkitOverflowScrolling: 'touch',
				},
				body: {
					width: '100%',
					height: '100%',
				},
				'#root': {
					width: '100%',
					height: '100%',
				},
				input: {
					'&[type=number]': {
						MozAppearance: 'textfield',
						'&::-webkit-outer-spin-button': {
							margin: 0,
							WebkitAppearance: 'none',
						},
						'&::-webkit-inner-spin-button': {
							margin: 0,
							WebkitAppearance: 'none',
						},
					},
				},
				textarea: {
					'&::-webkit-scrollbar': {
						width: '20px'
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: 'transparent'
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#d6dee1',
						borderRadius: '20px',
						border: '6px solid transparent',
						backgroundClip: 'content-box'
					},
					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#a8bbbf'
					}
				},
				img: {
					display: 'block',
					maxWidth: '100%',
				},
			},
		},
	};
}