import {
	collection,
	doc,
	limitToLast,
	orderBy,
	query,
	setDoc
} from 'firebase/firestore';
import React, { useEffect, useLayoutEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import { db } from '../firebaseconfig';
import UserChatBoxMessage from './UserChatBoxMessage';
import { useCollection } from 'react-firebase-hooks/firestore';
const UserChatBox = ({ scrollRef, open, status }) => {
	const { user } = useAuth();
	const q = query(
		collection(db, 'users', user.id, 'messages'),
		orderBy('createdAt', 'asc'),
		limitToLast(50)
	);
	const [messages] = useCollection(q);
	const updateDocRead = async () => {
		await setDoc(doc(db, 'users', user.id), {
			...status,
			userRead: true,
			hidden: false
		});
	};
	useEffect(() => {
		if(status && status.userRead === false) {
			updateDocRead();
		}
		// eslint-disable-next-line
  }, [open]);

	useLayoutEffect(() => {
		scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [messages]);

	return (
		messages &&
    status &&
    messages.docs.map((message, index) => {
    	return (
    		<UserChatBoxMessage
    			read={status.adRead}
    			chat={{ data: message.data(), id: message.id }}
    			last={index === messages.docs.length - 1}
    			key={message.id}
    			user={true}
    			onPress={updateDocRead}
    			lastFromReader={
    				index === messages.docs.length - 1
    					? !messages.docs[messages.docs.length - 1].data().fromAdmin
    					: false
    			}
    		></UserChatBoxMessage>
    	);
    })
	);
};

export default UserChatBox;