import { useMutation, useQuery } from '@tanstack/react-query';
import { getBuyerAccount, putUpdateBuyAccount } from '../api/queries';
import { Avatar, Button, Stack, TextField } from '@mui/material';
import *as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from './FormProvider';
import { useSnackbar } from 'notistack';
const validationSchema = Yup.object({
	profileName: Yup.string().required('Profile Name is required'),
	level: Yup.number().required('Level is required').min(1, 'Level must be at least 1'),
	tradelink: Yup.string().required('Trade link is required').url('Invalid URL format'),
});

const BuyerAccount = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { data, isLoading } = useQuery(
		['buyerAccount'],
		() => getBuyerAccount()
	);
	const onUpdateMutate = useMutation(putUpdateBuyAccount,{
		onSuccess:()=>{
			queryClient.invalidateQueries('buyerAccount');
			enqueueSnackbar('Buyer Account Updated');
		}
	});
	const defaultValues = useMemo(() => ({
		profileName: data?.profileName || 'Sonos',
		level: data?.level || 89,
		tradelink: data?.tradelink || 'https://steamcommunity.com/tradeoffer/new/?partner=1568684246&token=AyAri250',
		imageLink: data?.bilde || 'https://avatars.akamai.steamstatic.com/0ccbcddf2b1d3eb9cd8ddfde31f4ccb8ff150728_full.jpg',
	}), [data]);

	const methods = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});
	const { handleSubmit, control,watch } = methods;
	const values = watch();
	const onSubmit = (values) => {
		try{
			onUpdateMutate.mutate(values);
		}
		catch(err){
			console.log(err);
		}
	};
	if(isLoading) return <p>Loading..</p>;
	
	return (
		<FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
			<Stack direction="column" spacing={2}>
				<Avatar src={values?.imageLink ||data?.bilde} alt="Profile Image" />
				<Controller
					name="profileName"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							label="Profile Name"
							error={!!fieldState.error}
							helperText={fieldState.error ? fieldState.error.message : null}
						/>
					)}
				/>
				<Controller
					name="level"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							label="Level"
							type="number"
							error={!!fieldState.error}
							helperText={fieldState.error ? fieldState.error.message : null}
						/>
					)}
				/>
				<Controller
					name="tradelink"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							label="Trade Link"
							error={!!fieldState.error}
							helperText={fieldState.error ? fieldState.error.message : null}
						/>
					)}
				/>
				<Controller
					name="imageLink"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							label="Image Link"
							error={!!fieldState.error}
							helperText={fieldState.error ? fieldState.error.message : null}
						/>
					)}
				/>
				<Button type="submit"  variant="contained" color="primary">
          Submit
				</Button>
			</Stack>
		</FormProvider>
	);
};

export default BuyerAccount;