
import axios from 'axios';
// config


const baseURL =
  process.env.NODE_ENV === 'production'
  	? '/api/v1'
  	: 'http://localhost:3005/api/v1';

export default axios.create({
	baseURL,
	withCredentials: true
});