import { Box, Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { getUserIds } from '../../../api/queries';
import { db } from '../firebaseconfig';
import AdminUnhideSearch from './AdminUnhideSearch';
import RoomBox from './RoomBox';

const Rooms = ({ setActiveChatId, activeChatId }) => {
	const { data, isLoading } = useQuery(['userIds'], getUserIds);
	const q = query(
		collection(db, 'users'),
		orderBy('lastMessage', 'desc'),
		where('hidden', '==', false),
		limit(50)
	);
	const [rooms] = useCollection(q);

	if(isLoading) {
		return <p>Loading...</p>;
	}

	return !rooms
		? (
			<p>Loading...</p>
		)
		: (
			<>
				<Box
					sx={{
						position: 'fixed',
						right: '10%',
						width: { xl: '20%', lg: '23%', md: '30%' },
						height: '80%'
					}}
				>
					<Card sx={{ height: '90%', pt: 2, px: 2, overflowY: 'auto' }}>
						{rooms.docs.map((room) => (
							<RoomBox
								onPress={() => setActiveChatId(room.id)}
								setActiveChatId={setActiveChatId}
								id={room.id}
								name={data.find((e) => e.id === room.id)?.name}
								adRead={room.data().adRead}
								activeChatId={activeChatId}
								key={room.id}
							></RoomBox>
						))}
					</Card>
					<AdminUnhideSearch />
				</Box>
			</>
		);
};

export default Rooms;