import { Box, Button, Card, SwipeableDrawer } from '@mui/material';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import React, { useState } from 'react';

import { useCollection } from 'react-firebase-hooks/firestore';
import RoomBox from './RoomBox';
import { db } from '../firebaseconfig';
import { useQuery } from '@tanstack/react-query';
import { getUserIds } from '../../../api/queries';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const ChatDrawer = ({ setActiveChatId, activeChatId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { data, isLoading } = useQuery(['userIds'], getUserIds);
	const q = query(
		collection(db, 'users'),
		orderBy('lastMessage', 'desc'),
		where('hidden', '==', false),
		limit(50)
	);
	const [rooms] = useCollection(q);
	if(isLoading) {
		return <p>Loading...</p>;
	}

	return !rooms
		? <p>Loading...</p>
		: (
			<>
				<Button
					sx={{
						position: 'absolute',
						left: 0,
						top: 50,
						zIndex: 100
					}}
					onClick={() => setIsOpen(!isOpen)}
				>
					<ArrowForwardIcon></ArrowForwardIcon>
				</Button>
				<SwipeableDrawer
					open={isOpen}
					onOpen={() => setIsOpen(!isOpen)}
					onClose={() => setIsOpen(!isOpen)}
				>
					<Box
						sx={{
							width: '100%',
							height: '100%'
						}}
					>
						<Button
							sx={{
								position: 'absolute',
								zIndex: 100,
								right: 0,
								top: 0
							}}
							onClick={() => setIsOpen(!isOpen)}
						>
            X
						</Button>
						<Card sx={{ height: '100%', pt: 2, px: 2, overflowY: 'auto' }}>
							{rooms.docs.map((room) => (
								<RoomBox
									onPress={() => setActiveChatId(room.id)}
									setActiveChatId={setActiveChatId}
									id={room.id}
									name={data.find((e) => e.id === room.id)?.name}
									adRead={room.data().adRead}
									activeChatId={activeChatId}
									key={room.id}
								></RoomBox>
							))}
						</Card>
						{/* <AdminUnhideSearch /> */}
					</Box>
				</SwipeableDrawer>
			</>
		);
};

export default ChatDrawer;