import { TextField,InputAdornment } from '@mui/material';
import React from 'react';
import { Iconify } from '../../components';
const ShopSearch = ({ filter, setFilter,setHeight }) => {
	return (
		<TextField
			value={filter}
			onChange={(event) => setFilter(event.target.value)}
			placeholder="Søk..."
			fullWidth
			InputProps={{
				style:{
					height:setHeight!==null&&setHeight
				},
				startAdornment: (
					<InputAdornment position="start">
						<Iconify
							icon={'eva:search-fill'}
							sx={{ color: 'text.disabled', width: 20, height: 20 }}
						/>
					</InputAdornment>
				)
			}}
		></TextField>
	);
};

export default ShopSearch;