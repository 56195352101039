import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getBalance } from '../../../api/queries';
import ReferralCashoutInfo from './ReferralCashoutInfo';
import ReferralShop from './ReferralShop';

const ReferralCashoutShop = () => {
	const { data, isLoading } = useQuery(['balance'], getBalance, {
		refetchOnWindowFocus: false
	});
	if(isLoading) {
		return <p>Henter Balanse!</p>;
	}
	
	return (
		<>
			<Grid container>
				<Grid item sm={8}>
					<ReferralShop id={1} balance={data.balance}/>
				</Grid>
				<Grid item sm={4}>
					<Box
						sx={{
							position: 'fixed',
							right: '10%',
							width: { xl: '20%', lg: '23%', md: '30%' },
							height: '88%',
							zIndex: 1101
						}}
					>
						<ReferralCashoutInfo balance={data.balance} />
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default ReferralCashoutShop;