import {
	collection,
	doc,
	limitToLast,
	orderBy,
	query,
	setDoc
} from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import UserChatBoxMessage from '../ChatPublic/UserChatBoxMessage';
import { db } from '../firebaseconfig';

const AdminChatBox = ({ containerRef, activeChatId, status }) => {
	const q = query(
		collection(db, 'users', activeChatId, 'messages'),
		orderBy('createdAt', 'asc'),
		limitToLast(50)
	);
	const [messages] = useCollection(q);

	const updateDocRead = async () => {
		await setDoc(doc(db, 'users', activeChatId), {
			...status,
			adRead: true,
			hidden: false
		});
	};

	useEffect(() => {
		if(status.adRead === false) {
			updateDocRead();
		}
		// eslint-disable-next-line
  }, [activeChatId]);

	useEffect(() => {
		containerRef.current?.scrollIntoView({ behavior: 'smooth' });
		updateDocRead();
	}, [messages, containerRef]);

	return (
		messages &&
    messages.docs.map((message, index) => (
    	<UserChatBoxMessage
    		onPress={updateDocRead}
    		read={status.userRead}
    		chat={{ data: message.data(), id: message.id }}
    		key={message.id}
    		last={index === messages.docs.length - 1}
    		lastFromReader={
    			index === messages.docs.length - 1
    				? messages.docs[messages.docs.length - 1].data().fromAdmin
    				: false
    		}
    	></UserChatBoxMessage>
    ))
	);
};
export default AdminChatBox;