const conditionShortner = (condition) => {
	if(condition === 'Battle-Scarred') {
		return 'BS';
	}
	else if(condition === 'Well-Worn') {
		return 'WW';
	}
	else if(condition === 'Field-Tested') {
		return 'FT';
	}
	else if(condition === 'Minimal Wear' || condition === 'Minimal-Wear') {
		return 'MW';
	}
	else if(condition === 'FN') {
		return 'Factory New';
	}
	else if(condition === 'MW') {
		return 'Minimal Wear';
	}
	else if(condition === 'FT') {
		return 'Field-Tested';
	}
	else if(condition === 'WW') {
		return 'Well-Worn';
	}
	else if(condition === 'BS') {
		return 'Battle-Scarred';
	}
	else{
		return 'FN';
	}
};

export default conditionShortner;