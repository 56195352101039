import { Link, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { fDate } from '../../../utils/formatTime';
const UsersInvTableRow = ({ row }) => {
	const { id, name, inventory_value, last_updated } = row;

	return (
		<TableRow hover sx={{ width: '100%' }}>
			<TableCell>
				<Typography variant="subtitle2" noWrap>
					{id}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="subtitle2" noWrap>
					{name !== null ? name : 'Ikke oppgitt'}
				</Typography>
			</TableCell>
			<TableCell align="left" sx={{ fontFamily: 'Clash Grotesk Medium', color: '#fff' }}><Link href={'https://steamcommunity.com/profiles/' + id + '/inventory'} target="_blank" >Inv link</Link></TableCell>
			<TableCell align="center" sx={{ fontFamily: 'Clash Grotesk Medium', color: '#fff' }}>{inventory_value !== null ? 'Kr ' + inventory_value : 'Privat'}</TableCell>
			<TableCell align="center" sx={{ fontFamily: 'Clash Grotesk Medium', color: '#fff' }}>{fDate(last_updated)}</TableCell>
		</TableRow >
	);
};

export default UsersInvTableRow;