import { Container, Typography } from '@mui/material';
import LastDeals from './lastDeals';

const Downloads = () => {
	return (	
		<Container sx={{justifyContent:'center',display:'flex',flexDirection:'column',alignItems:'center'}}>
			<Typography variant="h1" sx={{textAlign:'center'}}>Last Deals</Typography>
			<LastDeals/>
		</Container>
	);
};

export default Downloads;