import { createContext, useEffect, useReducer } from 'react';
import axios from '../utils/axios';

// -----------------------------------------------------------------------


const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
	isAdmin: false,
};


const handlers = {
	INITIALIZE: (state, action) => {
		const { isAuthenticated, user, isAdmin } = action.payload;
		
		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user,
			isAdmin
		};
	},
	LOGIN: (state, action) => {
		const { user } = action.payload;
		
		return {
			...state,
			isAdmin: user.data.role === 'admin',
			isAuthenticated: true,
			user,
		};
	},
	LOGOUT: (state) => ({
		...state,
		isAuthenticated: false,
		isAdmin: false,
		user: null,
	})
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);


const AuthContext = createContext({
	...initialState,
	method: 'steam',
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
});

function AuthProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	useEffect(() => {
		const initialize = async () => {
			try{
				const user = await axios.get('/user/currentUser');
				if(user.data !== false) {
					const admin = user.data.role === 'admin';
					dispatch({
						type: 'INITIALIZE',
						payload: { isAuthenticated: true, user: user.data, isAdmin: admin },
					});
				}
				else{
					dispatch({
						type: 'INITIALIZE',
						payload: { isAuthenticated: false, user: null, isAdmin: false },
					});
				}
			}
			catch(err) {
				console.error(err);
				dispatch({
					type: 'INITIALIZE',
					payload: {
						isAuthenticated: false,
						user: null,
						isAdmin: false
					},
				});
			}
		};
		initialize();
	}, []);

	const login = async () => {
		const response = await axios.get('/auth/steam');
		const { user } = response.data;
		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			}
		});
	};

	const logout = async () => {
		const res = await axios.post('logout', { withCredentials: true });
		if(res.status === 200) {
			window.location.href = '/';
		}
		dispatch({
			type: 'LOGOUT',
		});

	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				login,
				logout
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
export{ AuthProvider, AuthContext };