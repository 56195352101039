import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import FaqsList from '../../../components/FaqsList';
import Page from '../../../components/Page';
import ContactForm from '../Contact/CareerContactForm';
const RootStyle = styled('div')(({ theme }) => ({
	paddingTop: theme.spacing(8),
	[theme.breakpoints.up('md')]: {
		paddingTop: theme.spacing(11)
	}

}));
const FrequentlyAsked = () => {
	return (
		<Page title="FAQ">
			<RootStyle>
				<Grid container spacing={5} sx={{ mx: 5 }}>
					<Grid item xs={12} md={6} sx={{ mt: 10 }}>
						<FaqsList></FaqsList>
					</Grid>
					<Grid item xs={12} md={6}>
						<ContactForm></ContactForm>
					</Grid>
				</Grid>
			</RootStyle>
		</Page >
	);
};

export default FrequentlyAsked;