import { Typography } from '@mui/material';
import { doc } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { db } from '../firebaseconfig';
import UserChatBox from './UserChatBox';

const Status = ({ chatBox, scrollRef, open, user, setNotifyAdmins }) => {
	const [status, isLoading] = useDocument(doc(db, 'users', user.id), {
		snapshotListenOptions: { includeMetadataChanges: true }
	});

	useEffect(() => {
		if(!isLoading && status && open) {
			if(status.data() && status.data().lastMessage) {
				Math.floor(Date.now() / 1000) - status.data().lastMessage.seconds <
          1800 && setNotifyAdmins(false);
			}
		}
	}, [status]);
	if(chatBox) {
		return !isLoading
			? (
				status.data()
					? (
						<UserChatBox scrollRef={scrollRef} open={open} status={status.data()} />
					)
					: (
						<p>Send oss din første melding!</p>
					)
			)
			: (
				<p>Loading...</p>
			);
	} else{
		return isLoading
			? (
				<p>...</p>
			)
			: (
				status.data() && !status.data().userRead && (
					<Typography variant='h5'>NY!</Typography>
				)
			);
	}
};

export default Status;