import {
	Box,
	Card,
	Container,
	Tab,
	Table,
	TableBody,
	TableContainer,
	TablePagination,
	Tabs
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCashoutsAdmin, postDeleteCashout } from '../../../api/queries';
import Label from '../../../components/Label';
import Page from '../../../components/Page';
import TableHeadCustom from '../../../components/table/TableHeadCustom';
import useTable, { getComparator } from '../../../hooks/useTable';
import useTabs from '../../../hooks/useTabs';
import CashOutTableRow from './CashOutTableRow';
const TABLE_HEAD = [
	{ id: 'deal_id', label: 'Steam_id', align: 'left' },
	{ id: 'created_at', label: 'Created', align: 'left' },
	{ id: 'bought_price', label: 'Amount', align: 'center', width: 140 },
	{ id: 'status', label: 'Status', align: 'center' },
	{ id: '' }
];
const AdminCashouts = () => {
	const queryClient = useQueryClient();
	const deleteMutation = useMutation(postDeleteCashout, {
		onSuccess: () => {
			queryClient.invalidateQueries('cashouts');
		}
	});
	const { enqueueSnackbar } = useSnackbar();
	const { currentTab: filterStatus, onChangeTab: onFilterStatus } =
    useTabs('all');
	const {
		page,
		order,
		orderBy,
		rowsPerPage,
		onChangePage,
		onChangeRowsPerPage
	} = useTable({ defaultOrderBy: 'created_at', defaultOrder: 'desc' });
	const navigate = useNavigate();
	const { data, isLoading } = useQuery(['cashouts'], getCashoutsAdmin);
	const handleEditRow = (id) => {
		navigate(`/admin/referrals/cashouts/${id}`);
	};
	if(isLoading) {
		return <p>Loading...</p>;
	}
	function getLengthByStatus(checkstatus) {
		return data.filter((e) => e.status === convertStatus(checkstatus)).length;
	}
	const dataFiltered = applySortFilter({
		data,
		comparator: getComparator(order, orderBy),
		filterStatus
	});
	const handleDeleteRow = async (id) => {
		deleteMutation.mutate({ id });
		enqueueSnackbar('Deleted', {
			variant: 'error'
		});
	};

	const TABS = [
		{ value: 'all', label: 'Alle', color: 'info', count: data.length },
		{
			value: 'Open',
			label: 'Open',
			color: 'secondary',
			count: getLengthByStatus('open')
		},
		{
			value: 'Godtatt',
			label: 'Godtatt',
			color: 'success',
			count: getLengthByStatus('godtatt')
		},
		{
			value: 'Declined',
			label: 'Declined',
			color: 'error',
			count: getLengthByStatus('declined')
		}
	];

	return (
		<Page title='Admin: Cashouts'>
			<Container maxWidth={'lg'} sx={{ mt: 2 }}>
				<Card>
					<Tabs
						value={filterStatus}
						onChange={onFilterStatus}
						sx={{ px: 2, bgcolor: 'background.neutral' }}
						allowScrollButtonsMobile
						variant='scrollable'
						scrollButtons='auto'
					>
						{TABS.map((tab) => (
							<Tab
								disableRipple
								key={tab.value}
								value={tab.value}
								icon={<Label color={tab.color}> {tab.count} </Label>}
								label={tab.label}
							/>
						))}
					</Tabs>
					<TableContainer>
						<Table size='medium'>
							<TableHeadCustom headLabel={TABLE_HEAD}></TableHeadCustom>
							<TableBody>
								{dataFiltered
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<CashOutTableRow
												row={row}
												key={row.cashoutid}
												convertStatus={convertStatus}
												onEditRow={() => handleEditRow(row.cashoutid)}
												onDeleteRow={() => handleDeleteRow(row.cashoutid)}
											></CashOutTableRow>
										);
									})}
							</TableBody>
						</Table>
						<Box sx={{ position: 'relative' }}>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								component='div'
								count={data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={onChangePage}
								onRowsPerPageChange={onChangeRowsPerPage}
							/>
						</Box>
					</TableContainer>
				</Card>
			</Container>
		</Page>
	);
};

export default AdminCashouts;

function applySortFilter({ data, comparator, filterStatus }) {
	const stabilizedThis = data.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if(order !== 0) return order;

		return a[1] - b[1];
	});
	data = stabilizedThis.map((el) => el[0]);

	if(filterStatus !== 'all') {
		data = data.filter((item) => {
			const itemStatus = convertStatus(item.status);

			return itemStatus === filterStatus;
		});
	}

	return data;
}

const convertStatus = (status) => {
	if(status === 1) {
		return 'Open';
	} else if(status === 2) {
		return 'Godtatt';
	} else if(status === 0) {
		return 'Declined';
	} else if(status === 'open') {
		return 1;
	} else if(status === 'godtatt') {
		return 2;
	} else if(status === 'declined') {
		return 0;
	}
};