import { Box, TableCell, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import Label from '../../../components/Label';
import { fDate } from '../../../utils/formatTime';
import EditIcon from '@mui/icons-material/Edit';
const CashOutTableRow = ({ row, convertStatus, onDeleteRow, onEditRow }) => {
	return (
		<TableRow>
			<TableCell>{row.steam_id}</TableCell>
			<TableCell align="left">{fDate(row.created_at)}</TableCell>
			<TableCell align="center">{parseInt(row.amount/100)} kr</TableCell>
			<TableCell align="center">
				<Label
					color={
						(convertStatus(row.status) === 'Godtatt' && 'success') ||
						(convertStatus(row.status) === 'Open' && 'secondary') ||
						(convertStatus(row.status) === 'Declined' && 'error') ||
						'default'
					}
					variant="filled"
					sx={{
						textTransform: 'capitalize',
						fontFamily: 'Clash Grotesk Medium'
					}}
				>
					{convertStatus(row.status)}
				</Label>
			</TableCell>
			<TableCell align="right">
				<Box>
					<EditIcon
						sx={{ ':hover': { cursor: 'pointer', color: 'info.main' } }}
						onClick={() => {
							onEditRow();
						}}
					/>
					<DeleteIcon
						sx={{ ':hover': { cursor: 'pointer', color: 'error.main' } }}
						onClick ={() => {
							onDeleteRow();
						}}
					/>
				</Box>
			</TableCell>
		</TableRow>
	);
};

export default CashOutTableRow;