import { Alert, Autocomplete, Box, Button, TextField } from '@mui/material';
import {
	collection,
	doc,
	orderBy,
	query,
	updateDoc,
	where
} from 'firebase/firestore';
import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../firebaseconfig';
const AdminUnhideSearch = () => {
	const q = query(
		collection(db, 'users'),
		orderBy('lastMessage', 'desc'),
		where('hidden', '==', true)
	);
	const [rooms] = useCollection(q);
	const [value, setValue] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [alertMessage, setAlertMessage] = useState('');
	const unHideRoom = async () => {
		if(rooms.docs.map((room) => room.id).includes(value)) {
			const roomRef = doc(db, 'users', value);
			await updateDoc(roomRef, { hidden: false });
		} else{
			setAlertMessage('Iden finnes ikke som unhidden');
		}
	};
	const onHandleSubmit = async () => {
		await unHideRoom();
		setValue('');
		setInputValue('');
	};
	if(!rooms) {
		return <p>Henter alle ider</p>;
	}

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
				<Autocomplete
					disablePortal
					id='combo-box-demo'
					options={rooms.docs.map((room) => room.id)}
					sx={{ width: 200, zIndex: 50 }}
					renderInput={(params) => <TextField {...params} label='Steam_id' />}
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
				/>
				<Button
					disabled={value === ''}
					variant='contained'
					size='medium'
					onClick={onHandleSubmit}
				>
          Unhide
				</Button>
				{alertMessage !== '' && <Alert>{alertMessage}</Alert>}
			</Box>
		</>
	);
};

export default AdminUnhideSearch;