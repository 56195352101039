import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
// @mui
import { alpha } from '@mui/material/styles';
import {
	Box,
	Stack,
	Card,
	Avatar,
	CardHeader,
	Typography
} from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumbers';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

ToppSelgere.propTypes = {
	list: PropTypes.array,
	title: PropTypes.string,
	subheader: PropTypes.string
};

export default function ToppSelgere({ title, subheader, list, ...other }) {
	return (
		<Card {...other}>
			<CardHeader title={title} subheader={subheader} />

			<Stack spacing={3} sx={{ p: 3 }}>
				{orderBy(list, ['sum'], ['asc']).map((author, index) => (
					<AuthorItem key={author.id} author={author} index={index} />
				))}
			</Stack>
		</Card>
	);
}

// ----------------------------------------------------------------------

AuthorItem.propTypes = {
	author: PropTypes.shape({
		name: PropTypes.string,
		avatar: PropTypes.string,
		favourite: PropTypes.number
	}),
	index: PropTypes.number
};

function AuthorItem({ author, index }) {
	return (
		<Stack direction='row' alignItems='center' spacing={2}>
			<Avatar alt={author.name} src={author.avatar} />

			<Box sx={{ flexGrow: 1 }}>
				<Typography variant='subtitle2'>{author.name}</Typography>

				<Typography
					variant='caption'
					sx={{
						mt: 0.5,
						display: 'flex',
						alignItems: 'center',
						color: 'text.secondary'
					}}
				>
					<Iconify icon='eva:dollar-fill' width={16} sx={{ mr: 0.5 }} />
					{fShortenNumber(author.sum)}
				</Typography>
			</Box>

			<Iconify
				icon='ant-design:trophy-filled'
				sx={{
					p: 1,
					width: 40,
					height: 40,
					borderRadius: '50%',
					color: 'primary.main',
					bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
					...(index === 1 && {
						color: 'info.main',
						bgcolor: (theme) => alpha(theme.palette.info.main, 0.08)
					}),
					...(index === 2 && {
						color: 'error.main',
						bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
					})
				}}
			/>
		</Stack>
	);
}