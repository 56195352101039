
import { createContext } from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from '../hooks/useLocalStorage';
const defaultSettings = {
	themeMode: 'dark'
};
SettingsProvider.propTypes = {
	children: PropTypes.node,
};
const initialState = {
	...defaultSettings,
	onToggleMode: () => { },
	onChangeMode: () => { },
};
const SettingsContext = createContext(initialState);
function SettingsProvider({ children }) {
	const [settings, setSettings] = useLocalStorage('settings', {
		themeMode: initialState.themeMode
	});
	const onToggleMode = () => {
		setSettings({
			...settings,
			themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
		});
	};
	const onChangeMode = (event) => {
		setSettings({
			...settings,
			themeMode: event.target.value,
		});
	};
	
	return (
		<SettingsContext.Provider value={{ ...settings, onToggleMode, onChangeMode }}>
			{children}
		</SettingsContext.Provider>
	);
}
export{ SettingsProvider, SettingsContext };