import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getItems } from '../../../api/queries';
import ShopProductList from '../../Sell/SkinList';
import ShopSearch from '../../Sell/SkinSearch';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
	Alert,
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Typography,
	AlertTitle,
	Container
} from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TuneIcon from '@mui/icons-material/Tune';
const ReferralShop = ({ balance = false }) => {
	const isLarge = useResponsive('up', 'lg');
	const isExtraLarge = useResponsive('up', 'xl');
	const { isLoading, data, refetch, isError, isFetching } = useQuery(
		['refSkins'],
		() => getItems(1),
		{
			refetchOnWindowFocus: true,
			staleTime: 600000,
			retryDelay: 5000
		}
	);
	const handleRefreshClick = () => {
		// manually refetch
		refetch();
	};
	const [filterInput, setFilter] = useState('');
	const [sort, setSort] = useState('maks');
	const [utilgjengligCheck, setUtilgjenlig] = useState(false);
	const handleChange = (event) => {
		setSort(event.target.value);
	};
	if(data === 'Too many attempts') {
		return (
			<Container>
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					<Typography>
            Vi har dessverre problemer med å nå steam sine servere for tiden.
            Vennligst prøv igjen senere!
					</Typography>
				</Alert>
			</Container>
		);
	}
	if(data === 'Inventory is private') {
		return (
			<Container>
				<Alert severity="warning">
					<AlertTitle>Warning</AlertTitle>
					<Typography>Inventoryen din er privat!</Typography>
				</Alert>
			</Container>
		);
	}
	if(isError) {
		return 'Steam er nede! Prøv igjen senere!';
	}

	return (
		<>
			{isLarge
				? (
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-evenly'
						}}
					>
						<Box sx={{ width: '40%' }}>
							<ShopSearch filter={filterInput} setFilter={setFilter} />
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '55%',
								alignItems: 'center'
							}}
						>
							<Box
								sx={{
									backgroundColor: '#12161B',
									borderRadius: '25px',
									p: 2,
									height: '100%'
								}}
							>
								<Typography
									sx={{
										color: '#52575C',
										fontFamily: 'Clash Grotesk Medium',
										fontWeight: 500,
										lineHeight: '15px'
									}}
									variant="body2"
								>
									{isLarge && balance === false
										? 'Inventory Verdi  '
										: 'Din balanse '}
									<Typography
										variant="body2"
										component="span"
										noWrap
										sx={{
											display: 'inline',
											fontFamily: 'Clash Grotesk Semibold',
											color: '#fff',
											lineHeight: '15px',
											fontWeight: 600
										}}
									>
										{balance}
									</Typography>
								</Typography>
							</Box>
							<FormControl size="small">
								<InputLabel>Retning</InputLabel>
								<Select label="direction" value={sort} onChange={handleChange}>
									<MenuItem value="maks">{isExtraLarge && 'Pris'} Maks</MenuItem>
									<MenuItem value="min">{isExtraLarge && 'Pris'} Min</MenuItem>
								</Select>
							</FormControl>
							<FormControlLabel
								checked={utilgjengligCheck}
								onChange={() => {
									setUtilgjenlig(!utilgjengligCheck);
								}}
								control={<Switch color="primary" />}
								label="Fjern utilgjenglig"
								labelPlacement="top"
							/>
							<Divider orientation="vertical" sx={{ my: 2 }} flexItem />
							<Box sx={{ display: 'flex' }}>
								<Button
									onClick={handleRefreshClick}
									startIcon={<RefreshIcon />}
									variant="text"
								>
									{isExtraLarge && 'Refresh'}
								</Button>
							</Box>
						</Box>
					</Box>
				)
				: (
					<Box
						sx={{ display: 'flex', flexDirection: 'column', px: '10%', gap: 2 }}
					>
						<Box sx={{ width: '40%' }}>
							{sort === 'maks'
								? (
									<Button
										variant="contained"
										onClick={() => setSort('min')}
										sx={{ fontFamily: 'sans-serif', width: '100%' }}
										endIcon={<KeyboardArrowDownIcon fontSize="medium" />}
									>
										{isExtraLarge && 'Pris'} Maks
									</Button>
								)
								: (
									<Button
										variant="contained"
										onClick={() => setSort('maks')}
										sx={{ fontFamily: 'sans-serif', width: '100%' }}
										endIcon={<KeyboardArrowUpIcon />}
									>
										{isExtraLarge && 'Pris'} Min
									</Button>
								)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<Box sx={{ width: '90%' }}>
								<ShopSearch
									filter={filterInput}
									setFilter={setFilter}
									setHeight="2.25rem"
								/>
							</Box>
							<TuneIcon></TuneIcon>
						</Box>
					</Box>
				)}
			<Box
				sx={{
					height: { xs: '55vh', md: '100%' },
					overflowY: { xs: 'auto', md: 'visible' }
				}}
			>
				<ShopProductList
					isLoading={isLoading}
					isFetching={isFetching}
					balance={balance}
					itemList={
						data?.data !== 'Steam is down'
							? applyFilter(
								data,
								filterInput,
								isLoading,
								sort,
								utilgjengligCheck
							)
							: 'Steam is down, try again later!'
					}
				/>
			</Box>
		</>
	);
};
export default ReferralShop;

const applyFilter = (data, filterInput, isLoading, sort, utilgjengligCheck) => {
	if(filterInput !== '' && !isLoading) {
		let filteredData = data.itemList.filter((e) => {
			return e.fullName.toLowerCase().includes(filterInput.toLowerCase());
		});
		if(utilgjengligCheck === true) {
			filteredData = filteredData.filter(
				(skin) =>
					skin.liquidity &&
          skin.tradeability === true &&
          skin.buff_price !== 0 &&
          skin.bought_price > 200
			);
		}
		filteredData = filteredData.sort(function (x, y) {
			return y.tradeability - x.tradeability;
		});
		if(sort === 'maks') {
			filteredData = filteredData.sort((a, b) => {
				return b.buff_price - a.buff_price;
			});
		}

		return filteredData;
	} else if(!isLoading) {
		let array = data.itemList;
		let cleanArray = array.filter(
			(skin) => skin.liquidity && skin.tradeability
		);
		if(sort === 'maks') {
			cleanArray = cleanArray.sort((a, b) => {
				return b.buff_price - a.buff_price;
			});
		} else if(sort === 'min') {
			cleanArray = cleanArray.sort((a, b) => {
				return a.buff_price - b.buff_price;
			});
		}
		const ustabilTradeableArray = array.filter(
			(skin) => !skin.liquidity && skin.tradeability
		);

		let untradeableArray = array.filter(
			(skin) => !skin.tradeability && skin.liquidity
		);
		if(sort === 'maks') {
			untradeableArray = untradeableArray.sort((a, b) => {
				return b.buff_price - a.buff_price;
			});
		} else if(sort === 'min') {
			untradeableArray = untradeableArray.sort((a, b) => {
				return a.buff_price - b.buff_price;
			});
		}
		const ustabilUnTradeableArray = array.filter(
			(skin) => !skin.liquidity && !skin.tradeability
		);

		if(utilgjengligCheck === true) {
			array = cleanArray;
		} else{
			array = [
				...cleanArray,
				...ustabilTradeableArray,
				...untradeableArray,
				...ustabilUnTradeableArray
			];
		}

		return array;
	} else{
		return data;
	}
};